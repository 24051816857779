import { useContext, useEffect, useReducer, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';
import { useHotkeys } from 'react-hotkeys-hook';
import dayjs from 'dayjs';

import { Dialog, Dropdown, DropdownItem, GlobalContext, SlideOver, Toggle, Utils } from 'lynkco-up-core';
import { GenericObject } from 'lynkco-up-core/dist/types/types';

import { usePanelResize, useUrlQuery } from '../../hooks';
import { BookingsTable, BookingsFilters, BookingDetails } from '../../parts/Bookings';
import { FiltersState, TerminationText } from '../../parts/Bookings/types';
import { getBookings } from '../../services/bookings';
import { Booking, BookingsQuery, Status as BookingStatus } from '../../services/bookings/types';
import { bookingsReducer, bookingsInitialState, useBookingActions } from './bookingsReducer';
import { getBookingTerminationText, terminateBooking } from '../../parts/Bookings/utils';
import { SelectedBookingContext, SelectedBookingProvider } from './selectedBookingContext';
import { useSelectedBookingActions } from './selectedBookingReducer';
import getBookingById from '../../services/bookings/getBookingById';
import { colorStatusMap } from '../../components/StatusDisk';
import BookingQuickFilter from '../../parts/Bookings/bookingsQuickFilter';

// import mockBookings from '../../__data-mocks__/bookings.json';

enum EndBookingType {
  End = 'end',
  ForceEnd = 'force end',
}

export type SortColumnType = {
  start: number;
  end: number;
};

const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
const premadeDatesFormat = 'YYYY-MM-DD';
const premadeDatesDefaultLabel = 'Today';
const manualDatesLabel = 'Manual dates';

const defaultStart = dayjs().startOf('day').format(dateFormat);
const defaultEnd = dayjs().endOf('day').format(dateFormat);

const premadeDateRanges: { [key: string]: [string, string] } = {
  Today: [dayjs().startOf('day').format(premadeDatesFormat), dayjs().endOf('day').format(premadeDatesFormat)],
  'Last 7 days': [
    dayjs().startOf('day').subtract(7, 'day').format(premadeDatesFormat),
    dayjs().endOf('day').format(premadeDatesFormat),
  ],
  'Last 30 days': [
    dayjs().startOf('day').subtract(30, 'day').format(premadeDatesFormat),
    dayjs().endOf('day').format(premadeDatesFormat),
  ],
  'This week': [
    dayjs().day(1).startOf('day').format(premadeDatesFormat),
    dayjs().day(1).endOf('day').add(6, 'day').format(premadeDatesFormat),
  ],
  'Week to date': [
    dayjs().day(1).startOf('day').format(premadeDatesFormat),
    dayjs().endOf('day').format(premadeDatesFormat),
  ],
  'This month': [
    dayjs().startOf('month').format(premadeDatesFormat),
    dayjs().endOf('month').format(premadeDatesFormat),
  ],
  'Month to date': [
    dayjs().startOf('month').format(premadeDatesFormat),
    dayjs().endOf('day').format(premadeDatesFormat),
  ],
};

function Bookings() {
  const { loading, alert } = useContext(GlobalContext);
  const [, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState<BookingsQuery>({
    start: defaultStart,
    end: defaultEnd,
  });
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery(['bookings', query], () => getBookings(query), {
    refetchOnWindowFocus: false,
    retry: 2,
  });
  const [isTerminationDialogOpen, setIsTerminationDialogOpen] = useState(false);
  const detailsPanelSize = usePanelResize();
  const [bookingsState, bookingsDispatch] = useReducer(bookingsReducer, bookingsInitialState);
  const [isForceEnd, setIsForceEnd] = useState(false);
  const [isPaymentCancelled, setIsPaymentCancelled] = useState(false);
  const { setBookings, incrementPage, decrementPage } = useBookingActions(bookingsDispatch);
  const { state: selectedBookingState, dispatch: selectedBookingDispatch } = useContext(SelectedBookingContext);
  const { closeDetailsPanel, clearIsBookingTermination, setSelectedBooking, openDetailsPanel } =
    useSelectedBookingActions(selectedBookingDispatch);
  const searchQuery = useUrlQuery();
  const { isDetailsPanelOpen, selectedBooking, isBookingTermination } = selectedBookingState;
  const [statusQuickFilter, setStatusQuickFilter] = useState('All');
  const [pramadeDateRanges, setPramadeDateRanges] = useState<[string, string] | null>();
  const [pramadeDateLabel, setPramadeDatesLabel] = useState(premadeDatesDefaultLabel);
  const [columnSort, setColumnSort] = useState<SortColumnType>({ start: 1, end: 0 });

  function handleSortChange(field: string, value: number) {
    if (value) setColumnSort({ ...columnSort, [field]: -value });
    else setColumnSort({ ...{ start: 0, end: 0 }, [field]: 1 });
  }

  function handleCloseDialog() {
    setIsTerminationDialogOpen(false);
    clearIsBookingTermination();
  }

  function handleCloseBookingDetails() {
    closeDetailsPanel();

    // Remove bookingId from URL params on booking details close.
    setSearchParams(searchQuery.filter(item => item[0] !== 'bookingId') as GenericObject);
  }

  async function handleTerminateBooking() {
    loading.show();

    const [result] = await terminateBooking(
      selectedBooking,
      (text: string) => {
        alert.show(`${Utils.capitalizeText(text)} booking successful!`, 'success');
        refetch();
      },
      () => {
        alert.show('Booking termination failed!', 'error');
      },
      isForceEnd,
      isPaymentCancelled,
    );

    loading.hide();
    handleCloseDialog();

    if (result) {
      setSelectedBooking(result[0]);
    }
  }

  function handleUpdateFilters(filters: FiltersState, hasManualDates = true) {
    const statuses = filters.statuses.size ? (Array.from(filters.statuses) as BookingStatus[]) : undefined;
    const startDate = dayjs(filters.startDate);
    const endDate = dayjs(filters.endDate);
    const isValidStartDate = startDate.isValid();
    const isValidEndDate = endDate.isValid();
    const start = isValidStartDate ? startDate.startOf('day').format(dateFormat) : undefined;
    const end = isValidEndDate ? endDate.endOf('day').format(dateFormat) : undefined;

    const newQuery: BookingsQuery = {
      userId: filters.customerId || undefined,
      lenderId: filters.lenderId || undefined,
      orderReference: filters.orderReference || undefined,
      vin: filters.vin || undefined,
      regNumber: filters.regNr || undefined,
      bookingId: filters.bookingId || undefined,
      digitalKeyId: filters.digitalKeyId || undefined,
      paymentId: filters.paymentId || undefined,
      start,
      end,
      market: filters.country || undefined,
      statuses,
    };

    const hasActiveFilters = Object.values(newQuery).filter(Boolean).length > 0;

    if (hasManualDates && hasActiveFilters) {
      setPramadeDatesLabel(manualDatesLabel);
    }

    if (hasActiveFilters) {
      const searchParams = Object.entries(newQuery).reduce((query, [key, value]) => {
        if (value) {
          query[key] = value;
        }

        return query;
      }, {} as GenericObject);

      setSearchParams(searchParams);
    } else {
      newQuery.start = defaultStart;
      newQuery.end = defaultEnd;
      setPramadeDatesLabel(premadeDatesDefaultLabel);
      setSearchParams({});
    }

    setQuery(newQuery);
  }

  function handleUserSearch(customerID: string) {
    closeDetailsPanel();

    const newQuery: BookingsQuery = {
      userId: customerID,
    };
    setQuery(newQuery);
  }

  function handleToggleForceEnd() {
    setIsForceEnd(flag => !flag);
  }

  function handleToggleCapture() {
    setIsPaymentCancelled(!isPaymentCancelled);
    console.log(isPaymentCancelled);
  }

  function handleQuickFilterChange(label: string) {
    setStatusQuickFilter(label);
  }

  async function handleBookingId(bookingId: string) {
    loading.show();
    try {
      const result = await getBookingById(bookingId);
      if (result) {
        setSelectedBooking(result);
        openDetailsPanel();
      }
    } catch (e) {
      alert.show('Error loading the booking', 'error');
    }
    loading.hide();
  }

  function updatePramadeDateFilter(pramadeDate: string) {
    const dateRangeInput = premadeDateRanges[pramadeDate];
    setPramadeDateRanges(dateRangeInput);
    setPramadeDatesLabel(pramadeDate);
  }

  function sortData() {
    const name = (columnSort.start == 0 && 'end') || 'start';
    const sortedData = data.sort((a: Booking, b: Booking) => {
      const aDate = dayjs(a[name]);
      const bDate = dayjs(b[name]);

      if (aDate.isBefore(bDate)) {
        return columnSort[name];
      } else if (aDate.isAfter(bDate)) {
        return -columnSort[name];
      } else return 0;
    });
    return sortedData;
  }
  useEffect(() => {
    if (!isLoading && !isError) {
      const sortedData = sortData();

      if (statusQuickFilter === 'All') {
        setBookings(sortedData);
      } else {
        const bookingStatuses = Object.entries(colorStatusMap)
          .filter(([, { label }]) => label === statusQuickFilter)
          .map(([e]) => e);

        setBookings(sortedData.filter(booking => bookingStatuses.includes(booking.status)));
      }
    }

    // Toggle next line to add mock bookings
    // setBookings(data.concat(mockBookings as Booking[]));
  }, [data, columnSort, statusQuickFilter]);

  useEffect(() => {
    if (isError) {
      alert.show('Error loading bookings', 'error');
    }
  }, [isError]);

  useHotkeys(`Escape`, () => {
    handleCloseBookingDetails();
  });

  useEffect(() => {
    const shouldOpenTerminationDialog = selectedBooking !== null && isBookingTermination;
    setIsTerminationDialogOpen(shouldOpenTerminationDialog);
  }, [selectedBooking, isBookingTermination]);

  useEffect(() => {
    const bookingQuery = searchQuery.find(item => item[0] === 'bookingId');
    const bookingId = bookingQuery && bookingQuery[1];
    if (bookingId) {
      handleBookingId(bookingId);
    }
  }, []);

  useEffect(() => {
    if (pramadeDateRanges) {
      setPramadeDateRanges(null);
    }
  }, [pramadeDateRanges]);

  return (
    <>
      <Helmet>
        <title>{isDetailsPanelOpen ? `${selectedBooking?.id} - Car Sharing` : 'Bookings - Car Sharing'}</title>
      </Helmet>
      <BookingsFilters onUpdateFilters={handleUpdateFilters} dateInputs={pramadeDateRanges} />
      <div className={`lg:flex lg:justify-between lg:items-center border-b border-gray-200`}>
        <BookingQuickFilter bookings={data} onChange={handleQuickFilterChange} />
        <div className="sm:pr-8 py-2 pl-4 flex items-center justify-center">
          <Dropdown label={pramadeDateLabel} icon="keyboard_arrow_down" isBorderless>
            {Object.keys(premadeDateRanges).map(dateRangeLabel => (
              <DropdownItem
                onClick={() => updatePramadeDateFilter(dateRangeLabel)}
                isSelected={dateRangeLabel === pramadeDateLabel}
                key={dateRangeLabel}
                extraClasses="p-2 text-base">
                {dateRangeLabel}
              </DropdownItem>
            ))}
          </Dropdown>
        </div>
      </div>
      <BookingsTable
        data={bookingsState.displayedBookings}
        isLoading={isLoading}
        paginationStartIndex={
          /** Add 1 to start index because zero based array indexing is meaningless to humans */
          /** If the current page is 1 set the start index to 1 for the same reason */
          bookingsState.currentPage > 1 ? bookingsState.paginationStartIndex + 1 : 1
        }
        paginationEndIndex={Math.min(bookingsState.paginationQuantity, bookingsState.paginationEndIndex)}
        paginationQuantity={bookingsState.paginationQuantity}
        onPaginationNextChange={incrementPage}
        onPaginationPreviousChange={decrementPage}
        onColumnSortChange={handleSortChange}
        columnSort={columnSort}
      />
      <Dialog
        isOpen={isTerminationDialogOpen}
        title="Confirm booking termination"
        icon="warning"
        confirmText="Confirm"
        cancelText="Abort"
        onConfirm={() => handleTerminateBooking()}
        onClose={handleCloseDialog}
        onCancel={handleCloseDialog}>
        <div className="mt-4">
          {getBookingTerminationText(selectedBooking) === TerminationText.End ? (
            <div>
              <div className="flex justify-between items-center p-1">
                <Toggle isToggledOn={isForceEnd} onChange={handleToggleForceEnd} extraClasses="mr-2" />
                <p className="text-xs ml-1.5 align-middle">
                  End bookings by skipping checks on if doors and windows are closed and the car is locked
                </p>
              </div>
              <div className="flex justify-start items-center p-1">
                <Toggle isToggledOn={isPaymentCancelled} onChange={handleToggleCapture} extraClasses="mr-2" />
                <p className="text-xs ml-1.5 align-middle">End booking and cancel payment</p>
              </div>
              <p className="mt-2">{`Are you sure you want to ${
                isForceEnd ? EndBookingType.ForceEnd : EndBookingType.End
              } the selected booking?`}</p>
            </div>
          ) : (
            <>
              <p>{`Are you sure you want to ${TerminationText.Cancel} the selected booking?`}</p>
            </>
          )}
        </div>
      </Dialog>
      <SlideOver isOpen={isDetailsPanelOpen} onCancel={handleCloseBookingDetails} size={detailsPanelSize}>
        {selectedBooking && <BookingDetails onUserSearch={handleUserSearch} booking={selectedBooking} />}
      </SlideOver>
    </>
  );
}

function BookingsWrapper() {
  return (
    <SelectedBookingProvider>
      <Bookings />
    </SelectedBookingProvider>
  );
}

export default BookingsWrapper;
