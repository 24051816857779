import { axiosInstance } from '../shared';
import { serviceConfig } from '../../config';
import { AvailabilityQuery, Availability } from './types';

async function getAvailabilities(query: AvailabilityQuery): Promise<Availability[]> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.availabilities}`;
  let availabilities;
  try {
    const response = await axiosInstance.get(url, {
      params: query,
    });
    availabilities = response.data.availabilities;
  } catch (err) {
    throw new Error('Failed to get availabilities.');
  }

  return availabilities;
}

export default getAvailabilities;
