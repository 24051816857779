import axiosInstance from '../shared/axiosInstance';
import { serviceConfig } from '../../config';
import { Booking } from './types';

async function extendBooking(bookingId: string, endTime: string): Promise<Booking> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.extension}`;

  let result;

  try {
    const response = await axiosInstance.post(url, { bookingId: bookingId, endTime: endTime });
    result = response.data;
  } catch (err) {
    throw new Error('Failed to update booking.');
  }

  return result;
}

export default extendBooking;
