import { serviceConfig } from '../../config';
import { GetEUMarketInfo, GetMarketInfo } from '../bookings/types';
import { axiosInstance } from '../shared';

export async function getMarkets(): Promise<GetMarketInfo[]> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.customerServiceMarkets}`;
  let markets;

  try {
    const response = await axiosInstance.get(url);
    markets = response.data?.markets;
  } catch (err) {
    const error = String(err);
    if (error.includes('403')) throw new Error('Unauthorized');
    else throw new Error('Failed to get markets.');
  }

  return markets;
}

export async function getMarketsFromExcel(): Promise<GetEUMarketInfo[]> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.customerServiceEUMarkets}`;
  let markets;

  try {
    const response = await axiosInstance.get(url);
    markets = response.data;
  } catch (err) {
    throw new Error(String(err));
  }
  return markets;
}
