import { createContext, useReducer } from 'react';

import { Utils } from 'lynkco-up-core';

import {
  SelectedBookingAction,
  selectedBookingInitialState,
  selectedBookingReducer,
  SelectedBookingState,
} from './selectedBookingReducer';
import { ComponentWithChildren } from '../../types';

type SelectedBookingContext = {
  state: SelectedBookingState;
  dispatch: React.Dispatch<SelectedBookingAction>;
};

export const SelectedBookingContext = createContext<SelectedBookingContext>({
  state: selectedBookingInitialState,
  dispatch: Utils.noop,
});

SelectedBookingContext.displayName = 'SelectedBookingContext';

export function SelectedBookingProvider({ children }: ComponentWithChildren) {
  const [state, dispatch] = useReducer(selectedBookingReducer, selectedBookingInitialState);
  return <SelectedBookingContext.Provider value={{ state, dispatch }}>{children}</SelectedBookingContext.Provider>;
}
