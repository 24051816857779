import React, { useState } from 'react';
import { Checkbox, Link, TableCell, TableRow } from '@mui/material';
import Moment from 'react-moment';
import { Button } from 'lynkco-up-core';
import { Report } from '../../../services/bookings/types';
import addUserReportState from '../../../services/userreports/addUserReports';

interface ReportTableRowProps {
  type: string;
  report: Report;
}

function openInDashboard(type?: string, id?: string) {
  const appId = process.env.REACT_APP_SENDBIRD_APP_ID_PROD;
  window.open(`https://dashboard.sendbird.com/${appId}/${type}/${id}`, '_blank')?.focus();
}

const ReportTableRow: React.FC<ReportTableRowProps> = ({ report }) => {
  const [reviewed, setIsReviewed] = useState<boolean>(false);

  const handleCheckboxChange = async (id: string) => {
    try {
      // Replace with your actual API call to update the reviewed property
      const response = await addUserReportState(id);

      if (response) {
        // Update the local state to reflect the change
        setIsReviewed(true);
      } else {
        console.error('Failed to update reviewed property');
      }
    } catch (error) {
      console.error('Error updating reviewed property:', error);
    }
  };
  const channelType = 'group_channels';

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      hover
      style={{ backgroundColor: reviewed || report.reviewed ? 'rgb(174 214 184 / 79%)' : '' }}>
      <TableCell component="th" scope="row">
        <Link target="_blank" onClick={() => openInDashboard('users', report.reporting_user?.user_id)}>
          {report.reporting_user?.user_id}
        </Link>
      </TableCell>
      <TableCell>
        <Link target="_blank" onClick={() => openInDashboard(channelType, report.channel.channel_url)}>
          {report.channel.name}
        </Link>
      </TableCell>
      <TableCell>
        <Moment>{report.created_at * 1000}</Moment>
      </TableCell>
      <TableCell>
        {/* <Button
          variant="contained"
          target="_blank"
          onClick={(event) => openInDashboard(channelType, report.channel.channel_url)}
        >
          View channel↗
        </Button> */}
        <Button variant="primary" onClick={() => openInDashboard(channelType, report.channel.channel_url)}>
          View channel↗
        </Button>
      </TableCell>
      <TableCell>
        <Checkbox
          checked={report.reviewed || reviewed}
          disabled={report.reviewed || reviewed}
          onChange={() => handleCheckboxChange(report.channel.channel_url + report.created_at)}
          color="primary"
        />
        {report.reviewed || reviewed ? 'Reviewed' : 'Mark as reviewed'}
      </TableCell>
    </TableRow>
  );
};

export default ReportTableRow;
