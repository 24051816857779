import { serviceConfig } from '../../config';
import { axiosInstance } from '../../services/shared';
import { AxiosResponse } from 'axios';
import { GetHistoryLog } from './types';

async function getHistoryLogs() {
  try {
    const response: AxiosResponse<GetHistoryLog[]> = await axiosInstance.get(
      `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.historylog}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching history logs:', error);
    throw error;
  }
}

export { getHistoryLogs };
