import { ColDefinitionDetails } from '../../components';
import { DetailsSection, DetailsSectionHeading, durations, formatDate } from '../../parts/shared/utils';
import { GetHistoryLog } from './types';
import { ServiceFee } from '../../services/bookings/types';

type HistoryLogProps = {
  historyLog: GetHistoryLog | undefined;
};

function capitalizeWords(str: string) {
  const spacedString = str.replace(/([A-Z])/g, ' $1');
  return spacedString
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}
function getTitleByDurationKey(durationKey: string) {
  const duration = durations.find(d => d.durationKey === durationKey);
  return duration ? duration.title : null;
}
function HistoryLogDetail({ historyLog }: HistoryLogProps) {
  if (historyLog) {
    return (
      <>
        <DetailsSection>
          <DetailsSectionHeading>History Log Details</DetailsSectionHeading>
        </DetailsSection>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <ColDefinitionDetails label="Username">{historyLog.user?.username}</ColDefinitionDetails>
          <ColDefinitionDetails label="Action">{historyLog.action}</ColDefinitionDetails>
          <ColDefinitionDetails label="Action Date">{formatDate(historyLog.actionDate)}</ColDefinitionDetails>
          {historyLog.data.map((item, index) => {
            const key = Object.keys(item)[0];
            const value = item[key];
            return (
              <ColDefinitionDetails key={index} label={capitalizeWords(key)}>
                {Array.isArray(value) ? <FeeTable data={value}></FeeTable> : value}
              </ColDefinitionDetails>
            );
          })}
        </dl>
      </>
    );
  }
  return <h1>No Data to Show</h1>;
}
type FeeTableProps = {
  data: ServiceFee[];
};

const FeeTable: React.FC<FeeTableProps> = ({ data }) => {
  // Filter data to get only items with priceLevel "1_2"
  const filteredData = data.filter(item => item.priceLevel === 'FEE_PRICE_LEVEL_2');

  return (
    <table>
      <thead>
        <tr>
          <th>Duration</th>
          <th>Fee</th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item, index) => (
          <tr key={index}>
            <td>{getTitleByDurationKey(item.duration)}</td>
            <td>{item.fee}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default HistoryLogDetail;
