// UserContext.tsx
import { createContext, useContext, useEffect, useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { serviceConfig } from '../../config';
import { axiosInstance } from '../../services/shared';

export interface User {
  id: string;
  username: string;
  roles: string[];
  permissions: string[];
}

interface UserContextProps {
  user: User;
  loading: boolean;
  fetchUser: () => Promise<void>;
  checkAccess: (key: string) => boolean;
}

interface UserProviderProps {
  children: React.ReactNode;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const url = `${serviceConfig.endpoints.legacy.root}/${serviceConfig.endpoints.legacy.users}/me`;

  const [user, setUser] = useState<User>({} as User);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const fetchUser = async () => {
    try {
      const response = await axiosInstance.get(url);
      setUser(response.data);
    } catch (error) {
      console.log(error);
      navigate('/*');
    } finally {
      setLoading(false);
    }
  };

  const checkAccess = (access: string) => {
    // Implement your access checking logic here
    const { user } = useUserContext();

    if (user && user.roles) {
      // Check if user is not undefined and has roles
      if (user.permissions.includes(access)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return <UserContext.Provider value={{ user, loading, fetchUser, checkAccess }}>{children}</UserContext.Provider>;
};
