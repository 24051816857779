import { Availability } from '../../services/availabilities/types';

type AvailabilityState = {
  allAvailabilities: Availability[];
  displayedAvailabilities: Availability[];
  totalPages: number;
  currentPage: number;
  paginationStartIndex: number;
  paginationEndIndex: number;
  paginationQuantity: number;
  itemsPerPage: number;
};

enum AvailabiltiesActionType {
  SET_AVAILABILITIES = 'setAvailabilities',
  INCREMENT_PAGE = 'incrementPage',
  DECREMENT_PAGE = 'decrementPage',
}

type AvailabilitiesPayload = {
  availabilities: Availability[];
};

type AvailabilitiesAction = {
  type: AvailabiltiesActionType;
  payload?: AvailabilitiesPayload;
};

const defaultItemsPerPage = 20;

export const availabilitiesInitialState: AvailabilityState = {
  allAvailabilities: [] as Availability[],
  displayedAvailabilities: [] as Availability[],
  totalPages: 0,
  currentPage: 1,
  paginationStartIndex: 0,
  paginationEndIndex: defaultItemsPerPage,
  paginationQuantity: 0,
  itemsPerPage: defaultItemsPerPage,
};

export function availabilitiesReducer(state: AvailabilityState, action: AvailabilitiesAction) {
  switch (action.type) {
    case AvailabiltiesActionType.SET_AVAILABILITIES:
      if (action.payload) {
        const allAvailabilities = action.payload.availabilities;
        return {
          ...state,
          allAvailabilities,
          displayedAvailabilities: allAvailabilities.slice(state.paginationStartIndex, state.paginationEndIndex),
          totalPages: Math.ceil(allAvailabilities.length / state.itemsPerPage),
          paginationQuantity: allAvailabilities.length,
        };
      } else {
        console.warn('No payload passed to setBookings action, returning unmodified state.');
        return state;
      }
    case AvailabiltiesActionType.INCREMENT_PAGE: {
      if (state.currentPage < state.totalPages) {
        const newPage = state.currentPage + 1;
        const newStartIndex = state.paginationEndIndex;
        const newEndIndex = state.paginationEndIndex + state.itemsPerPage;
        return {
          ...state,
          displayedAvailabilities: state.allAvailabilities.slice(newStartIndex, newEndIndex),
          currentPage: newPage,
          paginationStartIndex: newStartIndex,
          paginationEndIndex: newEndIndex,
        };
      } else return state;
    }
    case AvailabiltiesActionType.DECREMENT_PAGE: {
      if (state.currentPage > 1) {
        const newPage = state.currentPage - 1;
        const newStartIndex = state.paginationStartIndex - state.itemsPerPage;
        const newEndIndex = state.paginationStartIndex;
        return {
          ...state,
          displayedAvailabilities: state.allAvailabilities.slice(newStartIndex, newEndIndex),
          currentPage: newPage,
          paginationStartIndex: newStartIndex,
          paginationEndIndex: newEndIndex,
        };
      } else return state;
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export function useAvailabilityActions(dispatch: React.Dispatch<AvailabilitiesAction>) {
  return {
    setAvailabilities: (availabilities: Availability[]) =>
      dispatch({ type: AvailabiltiesActionType.SET_AVAILABILITIES, payload: { availabilities } }),
    incrementPage: () => dispatch({ type: AvailabiltiesActionType.INCREMENT_PAGE }),
    decrementPage: () => dispatch({ type: AvailabiltiesActionType.DECREMENT_PAGE }),
  };
}
