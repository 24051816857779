import dayjs from 'dayjs';

import { Badge } from 'lynkco-up-core';

import { Booking } from '../../services/bookings/types';
import {
  getTimeDifference,
  getTimeDifferenceText,
  isExtendedBooking,
  isLateReturnBooking,
  isOvertimeBooking,
} from './utils';

const extraClasses = 'text-base font-medium justify-center my-0.5';

/** Used in the Bookings Table -> Highlight column */
function HighlightBadge({ booking }: { booking: Booking }) {
  const today = dayjs().toISOString();
  const isExtended = isExtendedBooking(booking);
  const isLate = isLateReturnBooking(booking);
  const isOvertime = isOvertimeBooking(booking);
  const startDate = booking.extension?.endTime || booking.end;
  const endDate = booking.actualEnd || today;
  const timeDifference = getTimeDifference(startDate, endDate);
  const delayType = isLate ? 'late' : 'overtime';
  const timeDifferenceText = `${getTimeDifferenceText(timeDifference)} ${delayType}`;

  if (isOvertime) {
    return <Badge color={'danger'} text={timeDifferenceText} extraClasses={extraClasses} />;
  }

  if (isLate) {
    return <Badge color={'warning'} text={timeDifferenceText} extraClasses={extraClasses} />;
  }

  if (isExtended) {
    return <Badge color={'purple'} text={'Extended'} extraClasses={extraClasses} />;
  }

  return null;
}

export default HighlightBadge;
