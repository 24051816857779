import { GenericFunction } from 'lynkco-up-core/dist/types/types';

import { DetailsPanelSize } from './types';

export async function copyToClipboard(value: string, onError: GenericFunction) {
  try {
    await navigator.clipboard.writeText(value);
  } catch (error) {
    onError();
  }
}

export const screenWidths = {
  small: 800,
  medium: 980,
  large: 1200,
  xl: 1680,
  xxl: 2280,
};

export function computePanelSize(): DetailsPanelSize {
  const windowWidth = window.innerWidth;
  let size = DetailsPanelSize.FULL;

  if (windowWidth >= screenWidths.small) {
    size = DetailsPanelSize.XL;
  }

  if (windowWidth >= screenWidths.medium) {
    size = DetailsPanelSize.LARGE;
  }

  if (windowWidth >= screenWidths.large) {
    size = DetailsPanelSize.MEDIUM;
  }

  if (windowWidth >= screenWidths.xl) {
    size = DetailsPanelSize.SMALL;
  }

  if (windowWidth >= screenWidths.xxl) {
    size = DetailsPanelSize.XS;
  }

  return size;
}
