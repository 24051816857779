import { axiosInstance } from '../shared';
import { serviceConfig } from '../../config';
import { User } from './types';
import axios, { AxiosError } from 'axios';
import { handleAction } from '../../modules/HistoryLog/historyLogService';
import { ActionStatus } from '../../modules/HistoryLog/types';
async function addUser(user: User): Promise<null | number> {
  const url = `${serviceConfig.endpoints.legacy.root}/${serviceConfig.endpoints.legacy.users}`;
  let result = null;
  const { username, roles } = user;
  const usernameTrimmed = username.trim().toLowerCase();

  try {
    const response = await axiosInstance.post(url, { username: usernameTrimmed, roles: roles });
    result = response.status;
    handleAction(ActionStatus.ADDED_USER, [{ username: usernameTrimmed }]);
  } catch (err) {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    if (axios.isAxiosError(err)) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.data.error) {
        const message = axiosError.response?.data.error;
        throw new Error(message);
      }
    }
  }
  return result;
}
export default addUser;
