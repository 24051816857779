import { Button, GlobalContext } from 'lynkco-up-core';
import { SetStateAction, useContext, useEffect, useState } from 'react';
import { addComment, getComments } from '../../services/bookings/comments';
import { AddComment, Booking, GetComment } from '../../services/bookings/types';

type CommentsProps = {
  booking: Booking;
};
function Comments({ booking }: CommentsProps) {
  const { alert, loading } = useContext(GlobalContext);
  const [comments, setComments] = useState<GetComment[]>([]);
  const [newComment, setNewComment] = useState('');

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
    setNewComment(e.target.value);
  };

  async function handleAddComment(commentToAdd: AddComment) {
    try {
      loading.show();
      await addComment(commentToAdd).finally(() => {
        loading.hide();
      });
    } catch (error) {
      alert.show(`Failed to add comment`, 'error');
      loading.hide();
    }
  }
  async function fetchComments() {
    try {
      const fetchedComments: GetComment[] = await getComments({ bookingId: booking.id });
      setComments(fetchedComments);
    } catch (error) {
      alert.show('Failed to fetch comments:', 'error');
    }
  }
  useEffect(() => {
    fetchComments();
  }, [booking.id]);
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (newComment.trim() === '') {
      alert.show('Comment cannot be empty', 'error');
      return;
    }

    const currentDate = new Date().toISOString().slice(0, 10);

    const commentToAdd: AddComment = {
      comment: newComment,
      date: currentDate,
      bookingId: booking.id,
    };
    handleAddComment(commentToAdd)
      .then(() => {
        fetchComments();
        setNewComment('');
      })
      .catch(() => {
        alert.show('Failed to add comment', 'error');
      });
  };

  return (
    <div className="min-h-screen">
      <div className="container mx-auto p-8">
        <h1 className="text-3xl font-semibold mb-4">Comments</h1>
        <div className="grid grid-cols-1 gap-4">
          {comments?.map(comment => (
            <div key={comment.id} className="bg-white p-4 rounded-md shadow-md">
              <div className="text-gray-600 mb-2">
                <span className="font-semibold" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  {isHovered ? comment.user?.username : comment.user?.username?.split('@')[0]}
                </span>{' '}
                - {comment.date}
              </div>
              <div style={{ whiteSpace: 'pre-line' }}>{comment.comment}</div>
            </div>
          ))}
        </div>
        <form className="mt-8" onSubmit={handleSubmit}>
          <textarea
            name="comment"
            placeholder="Your Comment"
            value={newComment}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <Button
            onClick={handleSubmit}
            variant="primary"
            extraClasses="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
            Submit Comment
          </Button>
        </form>
      </div>
    </div>
  );
}

export default Comments;
