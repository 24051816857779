import { axiosInstance } from '../shared';
import { serviceConfig } from '../../config';
import axios from 'axios';
import { EditMarketInfo } from '../bookings/types';

async function editMarket(marketInfo: EditMarketInfo, marketName: string): Promise<null | number> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.customerServiceMarkets}`;
  let result = null;

  try {
    const response = await axiosInstance.patch(url, { marketInfo, marketName });
    result = response.status;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw new Error('Failed to edit market');
    }
  }
  return result;
}
export default editMarket;
