import { Button } from 'lynkco-up-core';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { loginRequest } from '../../config/auth';
import useLogin from '../../hooks/useLogin';
import routes from '../../routing/routesDefinitions';
import { ComponentWithChildren } from '../../types';

function RouteGuard({ children }: ComponentWithChildren): JSX.Element {
  const { isAuthenticated } = useLogin(loginRequest);
  const navigate = useNavigate();

  const handleLoginRedirect = async () => {
    navigate(routes.login.path);
  };

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <Helmet>
        <title>Unauthorized - Car Sharing</title>
      </Helmet>
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-primary-600 sm:text-5xl">401</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Unauthorized</h1>
              <p className="mt-1 text-base text-gray-500">You need to log in first.</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Button onClick={handleLoginRedirect}>Login</Button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default RouteGuard;
