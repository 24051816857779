import { Helmet } from 'react-helmet';
import { PageHeading } from '../../components';
// import BookingRequestFilters from './BookingRequestFilters';
import { BaseSyntheticEvent, useContext, useEffect, useReducer, useState } from 'react';
import getBookingRequestErrors from '../../services/bookingrequesterrors/getBookingRequestErrors';
import { BookingRequestErrorData } from '../../services/bookings/types';
import {
  requestErrorsInitialState,
  requestErrorsReducer,
  useRequestErrorsActions,
} from './bookingRequestErrorsReducer';
import { GlobalContext, Input, Icon } from 'lynkco-up-core';
import RequestErrorsTable from '../../parts/BookingRequestErrors/requestErrorsTable';
const BookingRequestErrors = () => {
  const [requestErrorsState, requestErrorsDispatch] = useReducer(requestErrorsReducer, requestErrorsInitialState);
  const { setRequestErrors, increment, decrement } = useRequestErrorsActions(requestErrorsDispatch);
  const [data, setData] = useState<BookingRequestErrorData[]>([]);
  const [customerId, setCustomerId] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (e: BaseSyntheticEvent) => {
    setCustomerId(e.target.value);
  };
  const handleButtonClick = () => {
    fetchBookingRequestErrors();
  };
  const { alert } = useContext(GlobalContext);
  async function fetchBookingRequestErrors() {
    try {
      setIsLoading(true);
      const fetchedBookingRequestErrors: BookingRequestErrorData[] = await getBookingRequestErrors(customerId);
      fetchedBookingRequestErrors.map(item => {
        item.customerNumber = customerId;
      });
      setData(fetchedBookingRequestErrors);
    } catch (error) {
      alert.show('Failed to fetch error:', 'error');
    } finally {
      setIsLoading(false);
      setShowTable(true);
    }
  }
  useEffect(() => {
    customerId && fetchBookingRequestErrors();
  }, []);
  useEffect(() => {
    setRequestErrors(data);
  }, [data]);

  // function handleClearSeardh(){
  //      setShowTable(false);

  // }

  return (
    <div className="z-10 bookings-table">
      <Helmet>
        <title>Booking Request Errors</title>
      </Helmet>
      <PageHeading title="Booking Request Errors" description="Errors happening while requesting bookings">
        <div className="flex items-center gap-6">
          <div className="border rounded-md flex justify-start items-center mt-5">
            <Icon extraClasses="p-2" variant="gray" shape="circle" name="info" size="small" />
            <div className="text-gray-500  p-3 font-normal text-sm">
              <p>
                Search with borrower user Id <br /> or lender user Id
              </p>
            </div>
          </div>

          <Input
            errorText="Something is wrong"
            id="withTrailingButton"
            label="Enter User Id Here"
            name="withTrailingButton"
            placeholder="User Id"
            trailingButtonIcon="search"
            trailingButtonText="Search"
            onTrailingButtonClick={handleButtonClick}
            onChange={handleInputChange}
            type="text"
          />
        </div>
      </PageHeading>
      <div className="flex justify-center">
        <a
          href="https://lynkcoknowledgehub.atlassian.net/wiki/spaces/UMO/pages/399835341/FL+-+HTG+-+Identify+Car+Sharing+Booking+Request+Error+Codes"
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-lg p-2">
          A guide to error message descriptions and actions can be found here
        </a>
      </div>
      {showTable ? (
        <RequestErrorsTable
          data={requestErrorsState.displayedRequestErrors}
          paginationStartIndex={requestErrorsState.currentPage > 1 ? requestErrorsState.paginationStartIndex + 1 : 1}
          paginationEndIndex={Math.min(requestErrorsState.paginationQuantity, requestErrorsState.paginationEndIndex)}
          paginationQuantity={requestErrorsState.paginationQuantity}
          onPaginationNextChange={increment}
          onPaginationPreviousChange={decrement}
          customerId={customerId}
          isLoading={isLoading}
        />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <div className="mx-auto max-w-2xl text-center content">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Search with User ID</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">Search with User Id at the top right input box</p>
          </div>
        </div>
      )}
      {/* 
      <PageHeading
        title="Booking Request Errors"
        description="Errors happening while requesting bookings"></PageHeading> */}
      {/* <BookingRequestFilters /> */}
    </div>
  );
};

export default BookingRequestErrors;
