import { Alert } from 'lynkco-up-core';
import { Booking } from '../../services/bookings/types';
import { getBookingEndedMessage, isOvertimeBooking } from './utils';

function OvertimeAlert({ booking }: { booking: Booking }) {
  const isOvertime = isOvertimeBooking(booking);
  const type = isOvertime ? 'error' : 'warning';
  const message = `${booking.borrower.firstName} ${
    isOvertime
      ? 'has not returned the car on time and did not end the booking.'
      : 'ended the booking later than allowed.'
  }`;
  const heading = getBookingEndedMessage(booking);

  return (
    <div className="mb-3.5 -mt-2">
      <Alert type={type} heading={heading} body={message} />
    </div>
  );
}

export default OvertimeAlert;
