import { serviceConfig } from '../../config';
import { Report } from '../bookings/types';
import axiosInstance from '../shared/axiosInstance';

async function getUserReports(): Promise<Report[]> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.userreports}`;
  let userreports;

  try {
    const response = await axiosInstance.get(url);
    userreports = response.data;
  } catch (err) {
    throw new Error('Failed to get Reports.');
  }

  return userreports;
}

export default getUserReports;
