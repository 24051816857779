import { axiosInstance } from '../../shared';
import { serviceConfig } from '../../../config';
import { CommentsQuery, GetComment } from './../types';

async function getComments(query: CommentsQuery): Promise<GetComment[]> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.comments}`;
  let comments;

  try {
    const response = await axiosInstance.get(url, {
      params: query,
    });
    comments = response.data;
  } catch (err) {
    throw new Error('Failed to get Comments.');
  }

  return comments;
}

export default getComments;
