import { StatusNamesMap } from '../../parts/Bookings/types';
import { Status as BookingStatus } from '../../services/bookings/types';

export type StatusDiskProps = {
  status?: BookingStatus;
  extraClasses?: string;
};

export const colorStatusMap: StatusNamesMap = {
  [BookingStatus.ACCEPTED]: { color: 'bg-gray-500', label: 'Not started' },
  [BookingStatus.REJECTED]: { color: 'bg-gray-500', label: 'Not started' },
  [BookingStatus.REQUESTED]: { color: 'bg-gray-500', label: 'Not started' },
  [BookingStatus.CANCELLED_BY_PSP]: { color: 'bg-danger-500', label: 'Error' },
  [BookingStatus.CANCELLED_BY_ERROR]: { color: 'bg-danger-500', label: 'Error' },
  [BookingStatus.ACTIVE_BY_BORROWER]: { color: 'bg-yellow-500', label: 'Ongoing' },
  [BookingStatus.ACTIVE_BY_START]: { color: 'bg-yellow-500', label: 'Ongoing' },
  [BookingStatus.CANCELLED_BY_BORROWER]: { color: 'bg-purple-500', label: 'Cancelled' },
  [BookingStatus.CANCELLED_BY_EC]: { color: 'bg-purple-500', label: 'Cancelled' },
  [BookingStatus.CANCELLED_BY_LENDER]: { color: 'bg-purple-500', label: 'Cancelled' },
  [BookingStatus.CANCELLED_BY_TIMEOUT]: { color: 'bg-purple-500', label: 'Cancelled' },
  [BookingStatus.CANCELLED]: { color: 'bg-purple-500', label: 'Cancelled' },
  [BookingStatus.CLOSED]: { color: 'bg-primary-500', label: 'Completed' },
  [BookingStatus.FINISHED]: { color: 'bg-primary-500', label: 'Completed' },
};

export default function StatusDisk({
  status = BookingStatus.REQUESTED,
  extraClasses = '',
}: StatusDiskProps): JSX.Element {
  return <div className={`rounded-full w-3 h-3 ${colorStatusMap[status].color} ${extraClasses}`}></div>;
}
