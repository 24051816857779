import { GenericFunction } from 'lynkco-up-core/dist/types/types';
import { Utils } from 'lynkco-up-core';

import { Status as BookingStatus } from '../../services/bookings/types';
import StatusDisk, { colorStatusMap } from '../StatusDisk';

export type StatusTab = {
  label: string;
  current: boolean;
  count: number;
  id: number;
};

export type StatusTabsProps = {
  tabs: StatusTab[];
  onChange?: GenericFunction;
};

type TabAnchorProps = {
  label: string;
  current: boolean;
  count: number;
  onChange: GenericFunction;
};

const defaultOnChange = Utils.noop;
const defaultStatus = BookingStatus.REQUESTED;

const TabAnchor = ({ label, current, count, onChange }: TabAnchorProps): JSX.Element => {
  return (
    <a
      key={label}
      className={`${
        current
          ? ' border-primary-500 text-primary-600'
          : ` border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200`
      } text-base cursor-pointer border-b py-4 flex justify-start items-center whitespace-nowrap`}
      onClick={() => onChange(label)}
      aria-current={current ? 'page' : undefined}>
      <StatusDisk extraClasses="mr-2" status={getStatusByLabel(label)} />
      {label}
      {count !== undefined ? (
        <span
          className={`${current ? 'bg-primary-100 text-primary-600 border-primary-500' : 'bg-gray-100 text-gray-900'}
             ml-3 py-0.5 px-2.5 rounded-full text-xs  hidden  md:inline-block`}>
          {count}
        </span>
      ) : null}
    </a>
  );
};

function getStatusByLabel(statusLabel: string) {
  const [status] = Object.entries(colorStatusMap).find(([, { label }]) => label === statusLabel) || [defaultStatus];
  return status as BookingStatus;
}

export default function StatusTabs({ tabs, onChange = defaultOnChange }: StatusTabsProps): JSX.Element {
  return (
    <div>
      <div className="hidden sm:block">
        <nav className="-mb-px flex space-x-4 md:space-x-8" aria-label="Tabs">
          {tabs.map(tab => (
            <div key={tab.id} className="text-slate-900 sm:pl-1.5  md:pl-6 pr-0.5">
              <TabAnchor label={tab.label} current={tab.current} count={tab.count} onChange={onChange} />
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
}
