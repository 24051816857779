import { useContext } from 'react';

import { Alert, Button, Dropdown, DropdownItem, GlobalContext, Icon, RatingCard } from 'lynkco-up-core';

import { DetailsMap, ColDefinitionDetails, RowDefinitionDetails } from '../../components';
import { Availability } from '../../services/availabilities/types';
import { SelectedAvailabilityContext } from '../../modules/Availabilities/selectedAvailabilityContext';
import { useSelectedAvailabilityActions } from '../../modules/Availabilities/selectedAvailabilityReducer';
import {
  DetailsSection,
  DetailsSectionHeading,
  EquipmentList,
  formatDate,
  getBookingLocation,
  getCurrencyByMarket,
} from '../shared/utils';
import { copyToClipboard } from '../../utils';
import { copyFormattedDetailsToClipboard } from '../shared/utils';
import PriceAndCurrency from '../shared/PriceAndCurrency';
import { useUserContext } from '../../modules/Users/UserContext';

type OverviewProps = {
  availability: Availability;
};

function Overview({ availability }: OverviewProps) {
  const { alert } = useContext(GlobalContext);
  const { dispatch } = useContext(SelectedAvailabilityContext);
  const { setIsStopSharing } = useSelectedAvailabilityActions(dispatch);
  const isVehicleUnavailable = availability.deleted;
  const coordinates = {
    /** Set fallback to zero in order to evaluate it as falsey for the map displayed check */
    lat: availability.location.latitude,
    lng: availability.location.longitude,
  };
  const locationData = getBookingLocation(availability);
  const parkingInfo = availability.location.parkingInfo;
  const isMapDisplayed = coordinates.lat && coordinates.lng;

  function handleStopSharing() {
    setIsStopSharing();
  }

  function handleCopyToClipboard(value: string) {
    copyToClipboard(value, () => {
      alert.show('Error copying to clipboard.');
    });
  }

  function handleCopyAllDetailsToClipboard(availability: Availability) {
    copyFormattedDetailsToClipboard(availability, () => {
      alert.show('Error copying to clipboard.');
    });
  }

  function hasAccess(permission: string) {
    const { checkAccess } = useUserContext();
    return checkAccess(permission);
  }

  return (
    <>
      {isVehicleUnavailable && (
        <Alert
          type="error"
          heading="Availability no longer active"
          body={`This availability session was ended ${availability.deleted}`}
        />
      )}
      <div>
        <DetailsSection>
          <div className="flex justify-between items-center">
            <DetailsSectionHeading>Sharing details</DetailsSectionHeading>
            <div className="mb-7 mr-2">
              <Dropdown icon="expand_more" label="More" extraClasses="mr-2">
                <DropdownItem onClick={() => handleCopyAllDetailsToClipboard(availability)}>
                  <div className="flex justify-between items-center">
                    <span>All details</span>
                    <Icon name="content_copy" />
                  </div>
                </DropdownItem>
                <DropdownItem onClick={() => handleCopyToClipboard(availability.id)}>
                  <div className="flex justify-between items-center">
                    <span>Availability ID</span>
                    <Icon name="content_copy" />
                  </div>
                </DropdownItem>

                <DropdownItem onClick={() => handleCopyToClipboard(availability.lender.snowflakeId)}>
                  <div className="flex justify-between items-center">
                    <span>Lender ID</span>
                    <Icon name="content_copy" />
                  </div>
                </DropdownItem>
                <DropdownItem onClick={() => handleCopyToClipboard(availability.vehicle.vin)}>
                  <div className="flex justify-between items-center">
                    <span>Vehicle ID (VIN)</span>
                    <Icon name="content_copy" />
                  </div>
                </DropdownItem>
                <DropdownItem onClick={() => handleCopyToClipboard(availability.vehicle.regNr)}>
                  <div className="flex justify-between items-center">
                    <span>Vehicle License Plate</span>
                    <Icon name="content_copy" />
                  </div>
                </DropdownItem>
              </Dropdown>
              {hasAccess('FUNCTION_STOP_SHARING') && (
                <Button variant="danger" onClick={handleStopSharing}>
                  Stop sharing
                </Button>
              )}
            </div>
          </div>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <ColDefinitionDetails label="Lender">
              <RatingCard
                src={availability.lender.profilePicture}
                alt={availability.lender.firstName}
                label={availability.lender.firstName}
                ratingValue={availability.lender.rating}
              />
            </ColDefinitionDetails>
            <ColDefinitionDetails label="">{''}</ColDefinitionDetails>
            <ColDefinitionDetails label="Start">{formatDate(availability.start)}</ColDefinitionDetails>
            <ColDefinitionDetails label="End">{formatDate(availability.end)}</ColDefinitionDetails>
          </dl>
        </DetailsSection>
        <DetailsSection>
          <DetailsSectionHeading>Pick up & Drop off</DetailsSectionHeading>
          {isMapDisplayed && <DetailsMap coords={coordinates} parkingInfo={parkingInfo} locationData={locationData} />}
        </DetailsSection>
        <DetailsSection>
          <DetailsSectionHeading>Price details</DetailsSectionHeading>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <ColDefinitionDetails label="Cost per hour">
              <PriceAndCurrency
                value={availability.vehicle.price.hourly}
                currency={getCurrencyByMarket(availability.vehicle.market)}
              />
            </ColDefinitionDetails>
            <ColDefinitionDetails label="Cost per day">
              <PriceAndCurrency
                value={availability.vehicle.price.daily}
                currency={getCurrencyByMarket(availability.vehicle.market)}
              />
            </ColDefinitionDetails>
          </dl>
        </DetailsSection>
        <DetailsSection>
          <DetailsSectionHeading>Vehicle</DetailsSectionHeading>
          <div className="border-t">
            <dl className="divide-y divide-gray-200">
              <RowDefinitionDetails label="VIN">{availability.vehicle.vin}</RowDefinitionDetails>
              <RowDefinitionDetails label="License plate">{availability.vehicle.regNr}</RowDefinitionDetails>
              <RowDefinitionDetails label="Type">{availability.vehicle.model}</RowDefinitionDetails>
              <RowDefinitionDetails label="Equipment">
                <EquipmentList data={availability.vehicle.equipment} />
              </RowDefinitionDetails>
              <RowDefinitionDetails label="Groups">
                <EquipmentList data={availability.vehicle.groups} />
              </RowDefinitionDetails>
              <RowDefinitionDetails label="Market">{availability.vehicle.market}</RowDefinitionDetails>
            </dl>
          </div>
        </DetailsSection>
      </div>
    </>
  );
}

export default Overview;
