import { serviceConfig } from '../../config';
import { axiosInstance } from '../shared';
import { User } from './types';

async function deleteUser(user: User): Promise<null | number> {
  const url = `${serviceConfig.endpoints.legacy.root}/${serviceConfig.endpoints.legacy.users}/${user.id}/${user.roles[0]}`;
  let result = null;
  try {
    const response = await axiosInstance.delete(url);
    result = response.status;
  } catch (err) {
    throw new Error(`Failed to delete user: ${user.username}`);
  }

  return result;
}

export default deleteUser;
