import React from 'react';
import { Availability } from '../../services/availabilities/types';

export type SelectedAvailabilityState = {
  selectedAvailability: Availability | null;
  isDetailsPanelOpen: boolean;
  isStopSharing: boolean;
};

enum SelectedAvailabilityActionType {
  SET_SELECTED_AVAILABILITY = 'setSelectedAvailability',
  CLEAR_SELECTED_AVAILABILITY = 'clearSelectedAvailability',
  OPEN_DETAILS_PANEL = 'openDetailsPanel',
  CLOSE_DETAILS_PANEL = 'closeDetailsPanel',
  SET_IS_STOP_SHARING = 'setIsStopSharing',
  CLEAR_IS_STOP_SHARING = 'clearIsStopSharing',
}

export type SelectedAvailabilityAction = {
  type: SelectedAvailabilityActionType;
  payload?: Availability;
};

export const selectedAvailabilityInitialState: SelectedAvailabilityState = {
  selectedAvailability: null,
  isDetailsPanelOpen: false,
  isStopSharing: false,
};

export function selectedAvailabilityReducer(state: SelectedAvailabilityState, action: SelectedAvailabilityAction) {
  switch (action.type) {
    case SelectedAvailabilityActionType.SET_SELECTED_AVAILABILITY:
      if (action.payload) {
        return {
          ...state,
          selectedAvailability: action.payload,
        };
      } else {
        console.warn('No payload passed to setSelectedAvailability action, returning unmodified state.');
        return state;
      }
    case SelectedAvailabilityActionType.CLEAR_SELECTED_AVAILABILITY:
      return {
        ...state,
        selectedAvailability: null,
      };
    case SelectedAvailabilityActionType.OPEN_DETAILS_PANEL:
      if (state.selectedAvailability) {
        return {
          ...state,
          isDetailsPanelOpen: true,
        };
      } else {
        console.warn('No availability set to open details panel for, returning unmodified state.');
        return state;
      }
    case SelectedAvailabilityActionType.CLOSE_DETAILS_PANEL:
      return {
        ...state,
        isDetailsPanelOpen: false,
      };
    case SelectedAvailabilityActionType.SET_IS_STOP_SHARING:
      return {
        ...state,
        isStopSharing: true,
      };
    case SelectedAvailabilityActionType.CLEAR_IS_STOP_SHARING:
      return { ...state, isStopSharing: false };
    default:
      return state;
  }
}

export function useSelectedAvailabilityActions(dispatch: React.Dispatch<SelectedAvailabilityAction>) {
  return {
    setSelectedAvailability: (availability: Availability) =>
      dispatch({ type: SelectedAvailabilityActionType.SET_SELECTED_AVAILABILITY, payload: availability }),
    clearSelectedAvailability: () => dispatch({ type: SelectedAvailabilityActionType.CLEAR_SELECTED_AVAILABILITY }),
    openDetailsPanel: () => dispatch({ type: SelectedAvailabilityActionType.OPEN_DETAILS_PANEL }),
    closeDetailsPanel: () => dispatch({ type: SelectedAvailabilityActionType.CLOSE_DETAILS_PANEL }),
    setIsStopSharing: () => dispatch({ type: SelectedAvailabilityActionType.SET_IS_STOP_SHARING }),
    clearStopSharing: () => dispatch({ type: SelectedAvailabilityActionType.CLEAR_IS_STOP_SHARING }),
  };
}
