import { Actor, Vehicle, Location } from '../../services/bookings/types';
import { User } from '../../services/users/types';

export type HistoryLogquery = {
  userId?: string;
  lenderId?: string;
  vin?: string;
  start?: string;
  end?: string;
  deleted?: boolean;
  market?: string;
  regNumber?: string;
  availabilityId?: string;
};

export type GetHistoryLog = {
  id?: string;
  user?: User;
  date: string;
  action: string;
  actionDate: string;
  status: ActionStatus;
  actions: { action: string }[];
  vin: number;
  orderRef: string;
  data: Array<{ [key: string]: string }>;
};

export enum HistoryLogStatus {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
}

export type HistoryLogType = {
  id: string;
  start: string;
  end: string;
  location: Location;
  lender: Actor;
  vehicle: Vehicle;
  deleted?: string;
  status?: HistoryLogStatus;
};

export enum ActionStatus {
  MODIFYING_SERVICE_FEE = 'Modify Service Fee',
  MODIFYING_FUEL_FEE = 'Modify Fuel Price',
  REMOVING_AVAILABILITIES = 'Remove Availabilities',
  CANCELLED_BOOKING = 'Cancelled Booking',
  CANCELLED_BOOKING_CHECKS = 'Cancelled booking and skipped checks ',
  CANCELLED_PAYMENT_PAYMENT = 'Cancelled booking and ',
  ADDED_USER = 'Added User',
  DELETED_USER = 'Deleted User',
}
export type ActionStatusNamesMap = {
  [key in ActionStatus]: { color: string; label?: string };
};
