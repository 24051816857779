import axiosInstance from '../shared/axiosInstance';
import { serviceConfig } from '../../config';
import { Booking, Status } from './types';
import { handleAction } from '../../modules/HistoryLog/historyLogService';
import { ActionStatus } from '../../modules/HistoryLog/types';

type UpdateBookingStatus = Status.CANCELLED | Status.FINISHED;

async function updateBookings(
  bookings: Booking[],
  status: UpdateBookingStatus,
  forceEnd = false,
  cancelPayment = false,
): Promise<Booking[]> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.customerServiceBookings}/bulk?skipPreconditionChecks=${forceEnd}&cancelPaymentOnFinish=${cancelPayment}`;
  const payload = {
    bookingsToUpdate: bookings.map(booking => ({
      bookingId: booking.id,
      currentStatus: booking.status,
      vinNumber: booking.vehicle.vin,
    })),
    status,
  };
  let result;

  try {
    const response = await axiosInstance.patch(url, payload);
    result = response.data.bookings;
    handleAction(ActionStatus.CANCELLED_BOOKING, [
      { 'skip payment': String(cancelPayment) },
      { 'skip checks': String(forceEnd) },
      { bookingId: payload.bookingsToUpdate[0].bookingId },
      { vinNumber: payload.bookingsToUpdate[0].vinNumber },
    ]);
  } catch (err) {
    throw new Error('Failed to update bookings.');
  }

  return result;
}

export default updateBookings;
