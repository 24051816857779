import { axiosInstance } from '../../shared';
import { serviceConfig } from '../../../config';
import axios from 'axios';
import { AddComment } from '../types';

async function addComment(comment: AddComment): Promise<null | number> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.comments}`;
  let result = null;

  try {
    const response = await axiosInstance.post(url, comment);
    result = response.status;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw new Error('Failed to add comment');
    }
  }
  return result;
}
export default addComment;
