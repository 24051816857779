import { Link as RouterLink } from 'react-router-dom';
import { Link, Alert } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import ReportsTable from './reportsTable';
import { axiosInstance } from '../../../services/shared';
import { serviceConfig } from '../../../config';
import { Helmet } from 'react-helmet';
import { Report } from '../../../services/bookings/types';
import getUserReports from '../../../services/userreports/getUserReports';

interface DateRange {
  from: Date | null;
  to: Date | null;
}

const HomePage: React.FC = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [dateRange] = useState<DateRange>({ from: null, to: null });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<React.ReactNode | null>(null);

  const [reviewdIds, setReviewdIds] = useState<string[]>([]);

  useEffect(() => {
    const fetchReviewdIds = async () => {
      try {
        const response = await getUserReports();
        setReviewdIds(response.filter(item => item.sendbirdId).map(item => item.sendbirdId as string));
      } catch (error) {
        console.error('Error fetching reviewd IDs:', error);
      }
    };

    fetchReviewdIds();
  }, []); // Run once on component mount
  const fetchReports = useCallback(async () => {
    setIsLoading(true);
    let url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.sendbird}/reports`;

    if (dateRange.from && dateRange.to) {
      url += `?start_ts=${dateRange.from.valueOf()}&end_ts=${dateRange.to.valueOf()}`;
    }

    try {
      const response = await axiosInstance.get(url);

      if (response.status === 403) {
        setError(
          <span>
            Session Expired. Please{' '}
            <Link component={RouterLink} to="/login">
              login
            </Link>{' '}
            again.
          </span>,
        );
      } else {
        const data = await response.data;
        setReports(data.report_logs);
      }
    } catch (error) {
      setError('An error occurred while fetching reports.');
    } finally {
      setIsLoading(false);
    }
  }, [dateRange]);

  useEffect(() => {
    if ((dateRange.from && !dateRange.to) || (!dateRange.from && dateRange.to)) {
      return;
    }

    fetchReports();
  }, [dateRange, fetchReports]);

  const { channel } = reports.reduce(
    (acc, curr) => {
      if (curr.report_type === 'channel') {
        acc[curr.report_type].push(curr);
      }
      return acc;
    },
    { user: [], message: [], channel: [] } as Record<string, Report[]>,
  );
  const updatedReports = channel.map(report => ({
    ...report,
    reviewed: reviewdIds.includes(report.channel.channel_url + report.created_at),
  }));
  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <div className="min-h-screen">
        <div className="container mx-auto p-8">
          <h1 className="text-3xl font-semibold mb-4">Channel Reports</h1>
          <Alert
            severity="error"
            color="error"
            sx={{ mx: 'auto', my: 2 }}
            style={{ width: 'fit-content', visibility: error ? 'visible' : 'hidden' }}>
            {error}
          </Alert>
          <ReportsTable isLoading={isLoading} type={'channel'} data={updatedReports} />
        </div>
      </div>
    </>
  );
};

export default HomePage;
