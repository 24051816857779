import { useEffect, useReducer, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { Badge, Button, FilterPanel, Icon, Input, RadioButton } from 'lynkco-up-core';
import { ReactInputEventHandler } from 'lynkco-up-core/dist/types/types';

import { Market } from '../../services/bookings/types';
import { filtersInitialState, filtersReducer, useFilterActions } from '../shared/filtersReducer';
import { FiltersState } from '../Bookings/types';
import { useUrlQuery } from '../../hooks';

import './availabilitiesFilter.css';

type AvailabilitiesFiltersProps = {
  onUpdateFilters: (filters: FiltersState) => void;
};

const availabiltiesIds = [
  {
    id: 'customerId',
    placeholder: 'Borrower user ID...',
    icon: 'person',
  },
  {
    id: 'vin',
    placeholder: 'Vehicle ID (VIN)...',
    icon: 'directions_car',
  },
  {
    id: 'regNr',
    placeholder: 'Vehicle License Plate...',
    icon: 'payments',
  },
] as const;

const countries = [
  {
    id: 'allCountries',
    label: 'All',
    value: Market.Global,
  },
  {
    id: 'belgium',
    label: 'Belgium',
    value: Market.Belgium,
  },
  {
    id: 'france',
    label: 'France',
    value: Market.France,
  },
  {
    id: 'germany',
    label: 'Germany',
    value: Market.Germany,
  },
  {
    id: 'italy',
    label: 'Italy',
    value: Market.Italy,
  },
  {
    id: 'spain',
    label: 'Spain',
    value: Market.Spain,
  },
  {
    id: 'sweden',
    label: 'Sweden',
    value: Market.Sweden,
  },
  {
    id: 'netherlands',
    label: 'The Netherlands',
    value: Market.Netherlands,
  },
] as const;

const toggleFilterPanelKey = 'e';
const modifierKey = navigator.userAgent.includes('Mac') ? 'cmd' : 'ctrl';
const modifierKeySymbol = navigator.userAgent.includes('Mac') ? '⌘' : 'Ctrl';
const toggleFilterPanelShortcut = `${modifierKey}+${toggleFilterPanelKey}`;
const toggleFilterPanelShortcutSymbol = `${modifierKeySymbol}+${toggleFilterPanelKey}`.toUpperCase();

function getFilterToggleButton(): HTMLElement | null {
  return document.querySelector('.lynkco-filter-panel-filters-controls-expand');
}

function checkIfFilterPanelIsOpen() {
  return document.querySelector('.lynkco-filter-panel-filters-content-container') !== null;
}

function handleToggleFilterPanel(flag: 'open' | 'close') {
  const filterToggleButton = getFilterToggleButton();
  const isFilterPanelOpen = checkIfFilterPanelIsOpen();

  if (!filterToggleButton) {
    return;
  }

  switch (flag) {
    case 'open':
      if (!isFilterPanelOpen) {
        filterToggleButton.click();
      }
      break;
    case 'close':
      if (isFilterPanelOpen) {
        filterToggleButton.click();
      }
      break;
    default:
      break;
  }
}

function AvailabilitiesFilters({ onUpdateFilters }: AvailabilitiesFiltersProps) {
  const [hasPresetSearchParams, setHasPresetSearchParams] = useState(false);
  const [state, dispatch] = useReducer(filtersReducer, filtersInitialState);
  const { setTextFilter, setStatusFilter, clearFilters } = useFilterActions(dispatch);

  const numberOfActiveFilters = Object.values(state).reduce((acc, filter) => {
    const isActiveTextFilter = typeof filter === 'string' && filter.length;
    const isActiveStatusFilter = typeof filter === 'object' && filter.size;
    if (isActiveTextFilter) {
      return acc + 1;
    } else if (isActiveStatusFilter) {
      return acc + filter.size;
    } else {
      return acc;
    }
  }, 0);
  const searchQuery = useUrlQuery();

  const handleChange: ReactInputEventHandler = event => {
    const { type, value, id } = event.currentTarget;

    if (type === 'checkbox') {
      setStatusFilter(id, value);
    } else if (type === 'radio') {
      setTextFilter('country', value);
    } else {
      setTextFilter(id, value);
    }
  };

  const handleSetFilters = () => {
    onUpdateFilters(state);
  };

  function handleClearFilters() {
    clearFilters();

    const isFilterPanelOpen = checkIfFilterPanelIsOpen();

    if (!isFilterPanelOpen) {
      onUpdateFilters(filtersInitialState);
    }
  }

  useHotkeys(`${toggleFilterPanelShortcut}`, () => {
    const isFilterPanelOpen = checkIfFilterPanelIsOpen();
    const flag = isFilterPanelOpen ? 'close' : 'open';
    handleToggleFilterPanel(flag);
  });

  // Setting filter panel as open by default.
  useEffect(() => {
    handleToggleFilterPanel('open');
  }, []);

  /** Hydrate filters from search query on first render */
  useEffect(() => {
    if (searchQuery) {
      setHasPresetSearchParams(true);

      searchQuery.forEach(([key, value]) => {
        if (key === 'statuses') {
          setStatusFilter(key, value);
        } else {
          setTextFilter(key, value);
        }
      });
    }
  }, []);

  /** Call onUpdateFilters if a search query was present initially */
  useEffect(() => {
    if (hasPresetSearchParams) {
      handleSetFilters();
      setHasPresetSearchParams(false);
    }
  }, [hasPresetSearchParams]);

  return (
    <div className="border-b availabilties-filters">
      <FilterPanel
        onClearFilters={handleClearFilters}
        numberOfActiveFilters={numberOfActiveFilters}
        badgeText={toggleFilterPanelShortcutSymbol}>
        <div className="py-3 pr-3 p-7">
          <div className="flex max-w-5xl">
            <div className="basis-1/4 pr-6">
              <h3 className="font-medium text-base">Find by ID</h3>
              <div className="mt-4">
                {availabiltiesIds.map(({ id, placeholder, icon }) => (
                  <Input
                    leadingIcon={icon}
                    placeholder={placeholder}
                    id={id}
                    extraClasses={`mb-3`}
                    value={state[id]}
                    onChange={handleChange}
                    key={id}
                  />
                ))}
              </div>
            </div>
            <div className="basis-1/4 pr-6">
              <h3 className="font-medium text-base">From & To</h3>
              <div className="mt-4">
                <Input
                  type="date"
                  id="startDate"
                  value={state.startDate}
                  onChange={handleChange}
                  extraClasses="mb-3"
                  label="Availability start"
                />
                <Input
                  type="date"
                  id="endDate"
                  value={state.endDate}
                  onChange={handleChange}
                  label="Availability end"
                />
              </div>
            </div>
            <div className="basis-1/4 pr-6">
              <h3 className="font-medium text-base">Market</h3>
              <div className="mt-4">
                {countries.map(({ id, label, value }) => (
                  <RadioButton
                    id={id}
                    name={'country'}
                    labelText={label}
                    value={value}
                    onChange={handleChange}
                    key={id}
                    defaultChecked={value === state.country}
                    extraClasses="mb-3"
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="mt-8 w-1/4 flex ">
            <Button onClick={handleSetFilters}>
              Apply
              <span className="ml-2 flex items-center rounded">
                <Icon name="search" size="small" variant="light" padding={0} />
              </span>
            </Button>
            <Button onClick={() => handleToggleFilterPanel('close')} variant="white" extraClasses="ml-2">
              Close
              <span className="ml-2">
                <Badge text={toggleFilterPanelShortcutSymbol} color="gray" />
              </span>
            </Button>
          </div>
        </div>
      </FilterPanel>
    </div>
  );
}

export default AvailabilitiesFilters;
