import { serviceConfig } from '../../config';
import { axiosInstance } from '../shared';
import { CommentsQuery, GetUsageEvent } from './types';

async function getUsageEvents(query: CommentsQuery): Promise<GetUsageEvent[]> {
  const url =
    `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.customerServiceBookings}/` +
    query.bookingId +
    `/usageevents`;
  let events;

  try {
    const response = await axiosInstance.get(url, {
      params: query,
    });
    events = response.data.usageEvents;
  } catch (err) {
    throw new Error('Failed to get Events.');
  }

  return events;
}

export default getUsageEvents;
