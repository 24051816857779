import { useContext } from 'react';

import { Button, GlobalContext, Icon } from 'lynkco-up-core';

import { Availability } from '../../services/availabilities/types';
import { Booking } from '../../services/bookings/types';
import { DetailsSection, DetailsSectionHeading } from './utils';

type RawApiResponseProps = {
  data: Booking | Availability;
};

function RawApiResponse({ data }: RawApiResponseProps) {
  const { alert } = useContext(GlobalContext);

  async function copyAllToClipboard() {
    try {
      const value = JSON.stringify(data, null, '\t');

      await navigator.clipboard.writeText(value);
    } catch (error) {
      alert.show('Error copying to clipboard.');
    }
  }

  return (
    <DetailsSection>
      <div className="flex justify-between items-baseline">
        <DetailsSectionHeading>Raw Api Response</DetailsSectionHeading>
        <Button variant="white" onClick={copyAllToClipboard}>
          <div className="flex justify-between items-center">
            <span>Copy</span>
            <Icon size="small" name="content_copy" padding={0} />
          </div>
        </Button>
      </div>
      <h2 className="sr-only">raw api response</h2>
      <div className="border rounded-md">
        <pre className="break-all whitespace-pre-wrap">{JSON.stringify(data, null, '\t')}</pre>
      </div>
    </DetailsSection>
  );
}

export default RawApiResponse;
