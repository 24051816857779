import { formatDate } from '../shared/utils';

function AvailabilityTimeLabel(dateAndTime: string) {
  if (dateAndTime) {
    const formattedDate = formatDate(dateAndTime);
    const time = formattedDate.slice(0, 5);
    const date = formattedDate.slice(5);
    return (
      <span className="font-normal">
        {time} {date}
      </span>
    );
  } else return <span className="font-normal">No end time</span>;
}

export default AvailabilityTimeLabel;
