import { serviceConfig } from '../../config';
import { BookingRequestErrorData } from '../bookings/types';
import { axiosInstance } from '../shared';

async function getBookingRequestErrors(userId: string): Promise<BookingRequestErrorData[]> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.customerServiceBookings}/${userId}/createbookingfailureevents`;
  let brerrors;

  try {
    const response = await axiosInstance.get(url);
    brerrors = response.data?.failureEvents;
  } catch (err) {
    const error = String(err);
    if (error.includes('403')) throw new Error('Unauthorized');
    else throw new Error('Failed to get markets.');
  }

  return brerrors;
}

export default getBookingRequestErrors;
