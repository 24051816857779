import React from 'react';
import { BookingRequestErrorData } from '../../services/bookings/types';

type RequestErrorsState = {
  allRequestErrors: BookingRequestErrorData[];
  displayedRequestErrors: BookingRequestErrorData[];
  totalPages: number;
  currentPage: number;
  paginationStartIndex: number;
  paginationEndIndex: number;
  paginationQuantity: number;
  itemsPerPage: number;
};

enum RequestErrorsActionType {
  SET_REQUEST_ERRORS = 'setRequestErrors',
  INCREMENT_PAGE = 'incrementPage',
  DECREMENT_PAGE = 'decrementPage',
}

type RequestErrorsPayload = {
  requestErrors: BookingRequestErrorData[];
};

type RequestErrorsAction = {
  type: RequestErrorsActionType;
  payload?: RequestErrorsPayload;
};

const defaultItemsPerPage = 20;

export const requestErrorsInitialState: RequestErrorsState = {
  allRequestErrors: [] as BookingRequestErrorData[],
  displayedRequestErrors: [] as BookingRequestErrorData[],
  totalPages: 0,
  currentPage: 1,
  paginationStartIndex: 0,
  paginationEndIndex: defaultItemsPerPage,
  paginationQuantity: 0,
  itemsPerPage: defaultItemsPerPage,
};

export function requestErrorsReducer(state: RequestErrorsState, action: RequestErrorsAction) {
  switch (action.type) {
    case RequestErrorsActionType.SET_REQUEST_ERRORS:
      if (action.payload) {
        const allRequestErrors = action.payload.requestErrors;
        return {
          ...state,
          allRequestErrors,
          displayedRequestErrors: allRequestErrors.slice(state.paginationStartIndex, state.paginationEndIndex),
          totalPages: Math.ceil(allRequestErrors.length / state.itemsPerPage),
          paginationQuantity: allRequestErrors.length,
        };
      } else {
        console.warn('No payload passed to setRequestErrors action, returning unmodified state.');
        return state;
      }
    case RequestErrorsActionType.INCREMENT_PAGE: {
      if (state.currentPage < state.totalPages) {
        const newPage = state.currentPage + 1;
        const newStartIndex = state.paginationEndIndex;
        const newEndIndex = state.paginationEndIndex + state.itemsPerPage;
        return {
          ...state,
          displayedRequestErrors: state.allRequestErrors.slice(newStartIndex, newEndIndex),
          currentPage: newPage,
          paginationStartIndex: newStartIndex,
          paginationEndIndex: newEndIndex,
        };
      } else return state;
    }
    case RequestErrorsActionType.DECREMENT_PAGE: {
      if (state.currentPage > 1) {
        const newPage = state.currentPage - 1;
        const newStartIndex = state.paginationStartIndex - state.itemsPerPage;
        const newEndIndex = state.paginationStartIndex;
        return {
          ...state,
          displayedRequestErrors: state.allRequestErrors.slice(newStartIndex, newEndIndex),
          currentPage: newPage,
          paginationStartIndex: newStartIndex,
          paginationEndIndex: newEndIndex,
        };
      } else return state;
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export function useRequestErrorsActions(dispatch: React.Dispatch<RequestErrorsAction>) {
  return {
    setRequestErrors: (requestErrors: BookingRequestErrorData[]) =>
      dispatch({ type: RequestErrorsActionType.SET_REQUEST_ERRORS, payload: { requestErrors } }),
    increment: () => dispatch({ type: RequestErrorsActionType.INCREMENT_PAGE }),
    decrement: () => dispatch({ type: RequestErrorsActionType.DECREMENT_PAGE }),
  };
}
