import { Booking } from '../../services/bookings/types';

type BookingsState = {
  allBookings: Booking[];
  displayedBookings: Booking[];
  totalPages: number;
  currentPage: number;
  paginationStartIndex: number;
  paginationEndIndex: number;
  paginationQuantity: number;
  itemsPerPage: number;
};

enum BookingsActionType {
  SET_BOOKINGS = 'setBookings',
  INCREMENT_PAGE = 'incrementPage',
  DECREMENT_PAGE = 'decrementPage',
}

type BookingsPayload = {
  bookings: Booking[];
};

type BookingsAction = {
  type: BookingsActionType;
  payload?: BookingsPayload;
};

const defaultItemsPerPage = 20;

export const bookingsInitialState: BookingsState = {
  allBookings: [] as Booking[],
  displayedBookings: [] as Booking[],
  totalPages: 0,
  currentPage: 1,
  paginationStartIndex: 0,
  paginationEndIndex: defaultItemsPerPage,
  paginationQuantity: 0,
  itemsPerPage: defaultItemsPerPage,
};

export function bookingsReducer(state: BookingsState, action: BookingsAction) {
  switch (action.type) {
    case BookingsActionType.SET_BOOKINGS:
      if (action.payload) {
        const allBookings = action.payload.bookings;
        const displayedBookings = allBookings;

        return {
          ...state,
          allBookings,
          paginationStartIndex: bookingsInitialState.paginationStartIndex,
          paginationEndIndex: bookingsInitialState.paginationEndIndex,
          displayedBookings,
          currentPage: bookingsInitialState.currentPage,
          totalPages: Math.ceil(allBookings.length / state.itemsPerPage),
          paginationQuantity: allBookings.length,
        };
      } else {
        console.warn('No payload passed to setBookings action, returning unmodified state.');
        return state;
      }
    case BookingsActionType.INCREMENT_PAGE: {
      if (state.currentPage < state.totalPages) {
        const newPage = state.currentPage + 1;
        const newStartIndex = state.paginationEndIndex;
        const newEndIndex = state.paginationEndIndex + state.itemsPerPage;
        return {
          ...state,
          displayedBookings: state.allBookings.slice(newStartIndex, newEndIndex),
          currentPage: newPage,
          paginationStartIndex: newStartIndex,
          paginationEndIndex: newEndIndex,
        };
      } else return state;
    }
    case BookingsActionType.DECREMENT_PAGE: {
      if (state.currentPage > 1) {
        const newPage = state.currentPage - 1;
        const newStartIndex = state.paginationStartIndex - state.itemsPerPage;
        const newEndIndex = state.paginationStartIndex;
        return {
          ...state,
          displayedBookings: state.allBookings.slice(newStartIndex, newEndIndex),
          currentPage: newPage,
          paginationStartIndex: newStartIndex,
          paginationEndIndex: newEndIndex,
        };
      } else return state;
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export function useBookingActions(dispatch: React.Dispatch<BookingsAction>) {
  return {
    setBookings: (bookings: Booking[]) => dispatch({ type: BookingsActionType.SET_BOOKINGS, payload: { bookings } }),
    incrementPage: () => dispatch({ type: BookingsActionType.INCREMENT_PAGE }),
    decrementPage: () => dispatch({ type: BookingsActionType.DECREMENT_PAGE }),
  };
}
