import React, { useEffect, useReducer, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { Badge, Button, Checkbox, FilterPanel, Icon, Input, RadioButton } from 'lynkco-up-core';

import { Market, Status as BookingStatus } from '../../services/bookings/types';
import { StatusDisk } from '../../components';
import { filtersReducer, useFilterActions, filtersInitialState } from '../shared/filtersReducer';
import { FiltersState } from './types';
import { bookingStatusNames } from './utils';
import { useUrlQuery } from '../../hooks';

import './bookingsFilters.css';

type BookingFiltersProps = {
  onUpdateFilters: (filters: FiltersState, hasManualDates?: boolean) => void;
  dateInputs?: [string, string] | null;
};

const bookingStatuses: BookingStatus[] = [
  BookingStatus.REQUESTED,
  BookingStatus.ACCEPTED,
  BookingStatus.REJECTED,
  BookingStatus.CANCELLED_BY_ERROR,
  BookingStatus.CANCELLED_BY_PSP,
  BookingStatus.CANCELLED_BY_BORROWER,
  BookingStatus.CANCELLED_BY_LENDER,
  BookingStatus.CANCELLED_BY_EC, // Cancelled by support
  BookingStatus.CANCELLED_BY_TIMEOUT,
  BookingStatus.ACTIVE_BY_START, // Booking started
  BookingStatus.ACTIVE_BY_BORROWER, // Car picked up
  BookingStatus.CLOSED,
  BookingStatus.FINISHED, // Car returned
];

const bookingIds = [
  {
    id: 'customerId',
    placeholder: 'Borrower user ID...',
    icon: 'person',
  },
  {
    id: 'orderReference',
    placeholder: 'Order Reference...',
    icon: 'credit_card',
  },
  {
    id: 'vin',
    placeholder: 'Vehicle ID (VIN)...',
    icon: 'directions_car',
  },
  {
    id: 'regNr',
    placeholder: 'Vehicle License Plate...',
    icon: 'payments',
  },
  {
    id: 'bookingId',
    placeholder: 'Booking Id...',
    icon: 'badge',
  },
  // {
  //   id: 'digitalKeyId',
  //   placeholder: 'Digital Key ID...',
  //   icon: 'key',
  // },
] as const;

const countries = [
  {
    id: 'allCountries',
    label: 'All',
    value: Market.Global,
  },
  {
    id: 'belgium',
    label: 'Belgium',
    value: Market.Belgium,
  },
  {
    id: 'france',
    label: 'France',
    value: Market.France,
  },
  {
    id: 'germany',
    label: 'Germany',
    value: Market.Germany,
  },
  {
    id: 'italy',
    label: 'Italy',
    value: Market.Italy,
  },
  {
    id: 'spain',
    label: 'Spain',
    value: Market.Spain,
  },
  {
    id: 'sweden',
    label: 'Sweden',
    value: Market.Sweden,
  },
  {
    id: 'netherlands',
    label: 'The Netherlands',
    value: Market.Netherlands,
  },
] as const;

const toggleFilterPanelKey = 'e';
const modifierKey = navigator.userAgent.includes('Mac') ? 'cmd' : 'ctrl';
const modifierKeySymbol = navigator.userAgent.includes('Mac') ? '⌘' : 'Ctrl';
const toggleFilterPanelShortcut = `${modifierKey}+${toggleFilterPanelKey}`;
const toggleFilterPanelShortcutSymbol = `${modifierKeySymbol}+${toggleFilterPanelKey}`.toUpperCase();

function getFilterToggleButton(): HTMLElement | null {
  return document.querySelector('.lynkco-filter-panel-filters-controls-expand');
}

function checkIfFilterPanelIsOpen() {
  return document.querySelector('.lynkco-filter-panel-filters-content-container') !== null;
}

function handleToggleFilterPanel(flag: 'open' | 'close') {
  const filterToggleButton = getFilterToggleButton();
  const isFilterPanelOpen = checkIfFilterPanelIsOpen();

  if (!filterToggleButton) {
    return;
  }

  switch (flag) {
    case 'open':
      if (!isFilterPanelOpen) {
        filterToggleButton.click();
      }
      break;
    case 'close':
      if (isFilterPanelOpen) {
        filterToggleButton.click();
      }
      break;
    default:
      break;
  }
}

function BookingsFilters({ onUpdateFilters, dateInputs }: BookingFiltersProps) {
  const [hasPresetSearchParams, setHasPresetSearchParams] = useState(false);
  const [state, dispatch] = useReducer(filtersReducer, filtersInitialState);
  const { setTextFilter, setStatusFilter, clearStatusFilter, clearFilters } = useFilterActions(dispatch);
  const [isAllStatusesChecked, setIsAllStatusesChecked] = useState(false);
  const [hasManualDates, setHasManualDates] = useState(true);

  const numberOfActiveFilters = Object.values(state).reduce((acc, filter) => {
    const isActiveTextFilter = typeof filter === 'string' && filter.length;
    const isActiveStatusFilter = typeof filter === 'object' && filter.size;
    if (isActiveTextFilter) {
      return acc + 1;
    } else if (isActiveStatusFilter) {
      return acc + filter.size;
    } else {
      return acc;
    }
  }, 0);
  const searchQuery = useUrlQuery();

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { type, value, id } = event.currentTarget;

    if (type === 'checkbox') {
      setStatusFilter(id, value);
    } else if (type === 'radio') {
      setTextFilter('country', value);
    } else {
      if (id === 'startDate' || id === 'endDate') {
        setHasManualDates(true);
      }
      setTextFilter(id, value);
    }

    setIsAllStatusesChecked(false);
  }

  function handleSetFilters(hasManualDates?: boolean) {
    onUpdateFilters(state, hasManualDates);
  }

  function handleToggleAllStatuses() {
    if (!isAllStatusesChecked) {
      clearStatusFilter();
      setIsAllStatusesChecked(true);
      bookingStatuses.map(status => setStatusFilter(`status-${status}`, status));
      return;
    }

    setIsAllStatusesChecked(false);
    clearStatusFilter();
  }

  function handleClearFilters() {
    clearFilters();
    setIsAllStatusesChecked(false);

    const isFilterPanelOpen = checkIfFilterPanelIsOpen();

    if (!isFilterPanelOpen) {
      onUpdateFilters(filtersInitialState);
    }
  }

  useHotkeys(toggleFilterPanelShortcut, () => {
    const isFilterPanelOpen = checkIfFilterPanelIsOpen();
    const flag = isFilterPanelOpen ? 'close' : 'open';
    handleToggleFilterPanel(flag);
  });

  /** Hydrate filters from search query on first render */
  useEffect(() => {
    if (!searchQuery) {
      return;
    }

    let statusesCount = 0;
    setHasPresetSearchParams(true);

    searchQuery.forEach(([key, value]) => {
      if (key === 'statuses') {
        statusesCount++;
        setStatusFilter(key, value);
      } else {
        setTextFilter(key, value);
      }
    });

    if (statusesCount === bookingStatuses.length) {
      setIsAllStatusesChecked(true);
    }
  }, []);

  useEffect(() => {
    if (!dateInputs) {
      return;
    }

    const [startDate, endDate] = dateInputs;
    setTextFilter('startDate', startDate);
    setTextFilter('endDate', endDate);
    setHasManualDates(false);
    setHasPresetSearchParams(true);
  }, [dateInputs]);

  /** Call onUpdateFilters if a search query was present initially */
  useEffect(() => {
    if (hasPresetSearchParams) {
      handleSetFilters(hasManualDates);
      setHasPresetSearchParams(false);
    }
  }, [hasPresetSearchParams]);

  return (
    <div className="border-b bookings-filters">
      <FilterPanel
        onClearFilters={handleClearFilters}
        numberOfActiveFilters={numberOfActiveFilters}
        badgeText={toggleFilterPanelShortcutSymbol}>
        <div className="py-3 pr-3 p-7">
          <div className="flex max-w-5xl">
            <div className="basis-1/4 pr-6">
              <h3 className="font-medium text-base">Find by ID</h3>
              <div className="mt-4">
                {bookingIds.map(({ id, placeholder, icon }) => (
                  <Input
                    leadingIcon={icon}
                    placeholder={placeholder}
                    id={id}
                    extraClasses={`mb-3`}
                    value={state[id]}
                    onChange={handleChange}
                    key={id}
                  />
                ))}
              </div>
            </div>
            <div className="basis-1/4 pr-6">
              <h3 className="font-medium text-base">From & To</h3>
              <div className="mt-4">
                <Input
                  type="date"
                  id="startDate"
                  value={state.startDate}
                  onChange={handleChange}
                  extraClasses="mb-3"
                  label="Booking start"
                />
                <Input type="date" id="endDate" value={state.endDate} onChange={handleChange} label="Booking end" />
              </div>
            </div>
            <div className="basis-1/4 pr-6">
              <h3 className="font-medium text-base">Market</h3>
              <div className="mt-4">
                {countries.map(({ id, label, value }) => (
                  <RadioButton
                    id={id}
                    name={'country'}
                    labelText={label}
                    value={value}
                    onChange={handleChange}
                    key={id}
                    isChecked={value === state.country}
                    extraClasses="mb-3"
                  />
                ))}
              </div>
            </div>
            <div className="basis-1/4">
              <h3 className="font-medium text-base">Status</h3>
              <div className={`flex justify-between items-center mt-4 mb-3`}>
                <Checkbox
                  id={`status-all`}
                  label="All"
                  onChange={handleToggleAllStatuses}
                  isChecked={isAllStatusesChecked}
                />
              </div>
              {bookingStatuses.map(status => (
                <div key={status} className={`flex justify-between items-center mb-3`}>
                  <Checkbox
                    id={`status-${status}`}
                    label={bookingStatusNames[status]}
                    value={status}
                    isChecked={state.statuses.has(status)}
                    onChange={handleChange}
                  />
                  <StatusDisk status={status} />
                </div>
              ))}
            </div>
          </div>
          <div className="mt-8 w-1/4 flex">
            <Button onClick={() => handleSetFilters(hasManualDates)}>
              Apply
              <span className="ml-2 flex items-center rounded">
                <Icon name="search" size="small" variant="light" padding={0} />
              </span>
            </Button>
            <Button onClick={() => handleToggleFilterPanel('close')} variant="white" extraClasses="ml-2">
              Close
              <span className="ml-2">
                <Badge text={toggleFilterPanelShortcutSymbol} color="gray" />
              </span>
            </Button>
          </div>
        </div>
      </FilterPanel>
    </div>
  );
}

export default BookingsFilters;
