import { Market } from '../../services/bookings/types';
import { FilterActions, FiltersState } from '../Bookings/types';

export const filtersInitialState: FiltersState = {
  customerId: '',
  lenderId: '',
  orderReference: '',
  vin: '',
  regNr: '',
  bookingId: '',
  digitalKeyId: '',
  paymentId: '',
  statuses: new Set(),
  startDate: '',
  endDate: '',
  country: Market.Global,
};

type FilterAction = {
  type: FilterActions;
  payload?: {
    id: string;
    value: string | boolean;
  };
};

export function filtersReducer(state: FiltersState, action: FilterAction): FiltersState {
  switch (action.type) {
    case FilterActions.SET_TEXT_FILTER:
      if (action.payload) {
        const key = action.payload.id;
        const value = action.payload.value;

        return { ...state, [key]: value };
      } else {
        throw new Error(`${action.type} called without payload.`);
      }
    case FilterActions.SET_STATUS_FILTER:
      if (action.payload) {
        const value = action.payload.value;
        const newState = { ...state };
        newState.statuses = new Set(state.statuses);

        if (newState.statuses.has(value as string)) {
          newState.statuses.delete(value as string);
        } else {
          newState.statuses.add(value as string);
        }

        return newState;
      } else {
        throw new Error(`${action.type} called without payload.`);
      }
    case FilterActions.CLEAR_STATUS_FILTER:
      return { ...state, statuses: new Set() };
    case FilterActions.CLEAR_FILTERS:
      return { ...filtersInitialState };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export function useFilterActions(dispatch: React.Dispatch<FilterAction>) {
  return {
    setTextFilter: (id: string, value: string) =>
      dispatch({ type: FilterActions.SET_TEXT_FILTER, payload: { id, value } }),
    setStatusFilter: (id: string, value: string) =>
      dispatch({ type: FilterActions.SET_STATUS_FILTER, payload: { id, value } }),
    clearStatusFilter: () => dispatch({ type: FilterActions.CLEAR_STATUS_FILTER }),
    clearFilters: () => dispatch({ type: FilterActions.CLEAR_FILTERS }),
  };
}
