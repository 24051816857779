import { Icon } from 'lynkco-up-core';

import { currencies } from './utils';

const contentSpacingClasses = {
  half: 'basis-1/2',
  quarter: 'basis-1/4',
};

type PriceAndCurrencyProps = {
  value: number;
  currency: string;
};

function PriceAndCurrency({ value, currency }: PriceAndCurrencyProps) {
  const availableCurrency = currency in currencies ? currency : 'EUR';
  const currencySymbol = currencies[availableCurrency].symbol;
  return (
    <div className={` ${contentSpacingClasses.quarter} text-slate-900  flex items-center`}>
      <div className={`flex ${currencySymbol ? 'flex-row ' : 'flex-row-reverse'} items-center`}>
        {currencySymbol ? <Icon name={currencies[availableCurrency].symbol} padding={0} /> : availableCurrency}
        <div className={`${currencySymbol ? 'ml-1' : 'mr-1'}`}>{value?.toFixed(2)}</div>
      </div>
    </div>
  );
}

export default PriceAndCurrency;
