import { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';

import { Button, Dialog, GlobalContext, Icon, Input } from 'lynkco-up-core';
import { ReactInputEventHandler } from 'lynkco-up-core/dist/types/types';

import { PageHeading } from '../../components';
import { userRoles } from '../../parts/Users/utils';
import BOTable from '../../parts/shared/Tables/Table';
import { addUser, getUsers } from '../../services/users';
import { User } from '../../services/users/types';
import { useUserContext } from './UserContext';

type UnauthorizedMessageProps = {
  head: string;
  body: string;
};

const UnauthorizedMessage = ({ head, body }: UnauthorizedMessageProps) => {
  return (
    <div className="flex items-center justify-center h-full w-full">
      <div className="text-center h-min">
        <h3 className="font-bold text-black text-xl">{head}</h3>
        <p className="font-normal">{body}</p>
      </div>
    </div>
  );
};

function Users() {
  const { alert, loading } = useContext(GlobalContext);
  const initialUser: User = { id: '', username: '', roles: ['firstline'] };
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [hasUsernameError, setHasUsernameError] = useState(false);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [userToAdd, setUserToAdd] = useState(initialUser);
  const usernameRef = useRef<HTMLInputElement | null>(null);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [searchedText, setSearchedText] = useState<string>();

  const { data, isLoading, isError, error, refetch } = useQuery('users', getUsers, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  function handleOpenAddUserDialog() {
    setIsAddUserDialogOpen(true);
    setHasUsernameError(false);
    setUserToAdd(initialUser);
  }

  function handleCloseAddUserDialog() {
    setIsAddUserDialogOpen(false);
  }

  function handleUsernameBlur() {
    const emailRegex = /^\S+@\S+\.\S+$/;
    const hasError = userToAdd.username.length !== 0 && !emailRegex.test(userToAdd.username);
    setHasUsernameError(hasError);
  }

  const handleUsernameChange: ReactInputEventHandler = e => {
    const username = e.currentTarget.value;
    setUserToAdd({ ...userToAdd, username });
  };

  const handleRoleChange: ReactInputEventHandler = e => {
    setUserToAdd({ ...userToAdd, roles: [e.currentTarget.name] });
  };

  // Function to filter users based on search input
  const searchUsers = (query: string) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = data?.filter(user => user.username.toLowerCase().includes(lowercasedQuery));
    setFilteredUsers(filtered || []);
    setSearchedText(query); // Update searchedText state
  };

  async function handleAddUser() {
    if (hasUsernameError || userToAdd.username.length === 0) {
      alert.show('Please enter a valid email address');
      return;
    } else {
      try {
        loading.show();
        await addUser(userToAdd);
        alert.show(`User ${userToAdd.username} was added successfully.`, 'success');
        refetch();
      } catch (error) {
        alert.show(`Failed to add user ${userToAdd.username}` + ' ' + error, 'error');
      }
      handleCloseAddUserDialog();
      loading.hide();
    }
  }

  function isChecked(name: string) {
    return name == userToAdd.roles[0];
  }

  useEffect(() => {
    if (isLoading) {
      loading.show();
    } else {
      loading.hide();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isError) {
      const errorText = String(error);
      setIsUnauthorized(errorText.includes('Unauthorized'));
      alert.show('Failed to load users.');
    }
  }, [isError]);

  useEffect(() => {
    setTimeout(() => {
      if (isAddUserDialogOpen && usernameRef.current) {
        usernameRef.current.focus();
      }
    }, 0);
  }, [usernameRef.current, isAddUserDialogOpen]);

  function hasAccess(roleValue: string) {
    const scope = 'FUNCTION_ASSIGN_ROLE_' + roleValue.toUpperCase();
    const { checkAccess } = useUserContext();
    return checkAccess(scope);
  }

  return (
    <div className="h-full">
      <Helmet>
        <title>Users - Car Sharing</title>
      </Helmet>
      {isUnauthorized ? (
        <UnauthorizedMessage head="Permission denied" body="You do not have the permission to see or manange users" />
      ) : (
        <>
          <PageHeading
            title="Users"
            description="A list of users with access to the application and their assigned roles.">
            <div className="flex content-center items-center gap-6">
              <Input
                leadingIcon="search"
                placeholder="Search User"
                extraClasses="w-72"
                name="userName"
                type="email"
                id="userId"
                onChange={e => {
                  handleUsernameChange(e);
                  searchUsers(e.currentTarget.value);
                }}
              />
              <Button size="small" extraClasses="mr-1" variant="white" onClick={() => handleOpenAddUserDialog()}>
                <div className="flex justify-between items-center">
                  <p className="font-medium">Add user</p>
                  <Icon size="medium" name="person_add" />
                </div>
              </Button>
            </div>
          </PageHeading>

          {filteredUsers.length > 0 ? (
            <BOTable data={filteredUsers} refetch={refetch}></BOTable>
          ) : searchedText ? (
            <p className="text-center text-2xl mr-20 mt-8 pl-8 mb-8 text-gray-500">No users found.</p>
          ) : (
            <BOTable data={data} refetch={refetch}></BOTable>
          )}

          <Dialog
            icon="person_add"
            onCancel={() => handleCloseAddUserDialog()}
            onClose={() => handleCloseAddUserDialog()}
            onConfirm={() => handleAddUser()}
            isOpen={isAddUserDialogOpen}
            title="Add user"
            confirmText="Save"
            cancelText="Cancel">
            <div className="mt-4">
              <Input
                extraClasses="w-80"
                placeholder="Email..."
                name="userName"
                type="email"
                id="userId"
                onBlur={handleUsernameBlur}
                hasError={hasUsernameError}
                isRequired={true}
                errorText={'The username must be an email'}
                onChange={handleUsernameChange}
                ref={usernameRef}
              />
              <div className="py-2 text-gray-600 text-sm">
                {userRoles.map(
                  role =>
                    hasAccess(role.value) && (
                      <div key={role.value} className="flex justify-start items-center py-1">
                        <input
                          className="focus:ring-primary-500 text-primary-600"
                          name={role.value}
                          id={role.value}
                          value={role.value}
                          type="radio"
                          onChange={handleRoleChange}
                          checked={isChecked(role.value)}
                          hidden={true}
                        />
                        <label className="ml-3" htmlFor={role.value}>
                          {role.label}
                        </label>
                      </div>
                    ),
                )}
              </div>
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default Users;
