import axios from 'axios';
import axiosInstance from '../shared/axiosInstance';
import { serviceConfig } from '../../config';

async function addUserReportState(reportId: string): Promise<null | number> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.userreports}`;
  let result = null;

  try {
    const response = await axiosInstance.put(url, { sendbirdId: reportId });
    result = response.status;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw new Error('Failed to add comment');
    }
  }
  return result;
}
export default addUserReportState;
