import { useContext } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

import { GlobalContext } from 'lynkco-up-core';
import { PopupRequest } from '@azure/msal-browser';
import { GlobalState } from 'lynkco-up-core/dist/types/state';

type UseLogin = {
  isAuthenticated: boolean;
  tryLogin: () => void;
};

function useLogin(loginRequest: PopupRequest): UseLogin {
  const { instance } = useMsal();
  const context: GlobalState = useContext(GlobalContext);
  const isAuthenticated = useIsAuthenticated();

  if (!context) {
    throw new Error('useLogin must be used within a GlobalContextProvider');
  }

  const { loading, alert } = context;

  const tryLogin = async () => {
    loading.show();

    try {
      await instance.loginPopup(loginRequest);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let message = 'Unknown error';

      if (error?.errorMessage) {
        message = error.errorMessage;
      }

      alert.show(message, 'error');
    }

    loading.hide();
  };

  return {
    isAuthenticated,
    tryLogin,
  };
}

export default useLogin;
