import { Icon } from 'lynkco-up-core';
import { GenericFunction } from 'lynkco-up-core/dist/types/types';

type SortCellProps = {
  /** Number indicating sort direction: 0 = inactive, 1 = descending, -1 = ascending */
  sortValue: number;

  /** Name of the column that is being sorted by: "end", "start"*/
  sortLabel: string;

  /** Function to be called when the sort cell is clicked */
  onClick: GenericFunction;
};

function SortCell({ sortValue, sortLabel, onClick }: SortCellProps) {
  const opacityClass = sortValue === 0 ? `opacity-0` : `opacity-100`;
  const rotateClass = sortValue === -1 ? `rotate-180` : ``;

  return (
    <div
      className="p-2 py-3.5 font-medium cursor-pointer text-sm text-neutral-400 flex items-center"
      onClick={() => onClick(sortLabel, sortValue)}>
      {sortLabel.toUpperCase()}
      <Icon
        extraClasses={`cursor-pointer transition ${opacityClass} ${rotateClass} mb-1`}
        variant="primary"
        name="arrow_upward"
        size="small"
      />
    </div>
  );
}
export default SortCell;
