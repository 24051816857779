import { Avatar, StarCounter } from 'lynkco-up-core';
import { Booking } from '../../services/bookings/types';

type RatingsProps = {
  booking: Booking;
};

type RatingCardProps = {
  rater: string;
  ratee: string;
  avatar: string;
  rating: number;
  comment: string;
  isBorrower?: boolean;
};

function RatingBlock({ rater, ratee, avatar, rating, comment, isBorrower = false }: RatingCardProps) {
  return (
    <div className="flex text-base text-gray-500 space-x-4 px-3">
      <div className="flex-none py-10">
        <Avatar src={avatar} alt={rater} />
      </div>
      <div className="flex-1 py-10 -mt-1">
        <h3 className="text-gray-900">
          {rater} ({isBorrower ? 'Borrower' : 'Lender'})
        </h3>
        <h4 className="text-gray-500">Rating {ratee}</h4>
        <div className="flex mt-2">
          <StarCounter ratingValue={rating} size="small" ratingTextAlignment="right" extraClasses="-ml-2" />
        </div>
        {comment ? (
          <p
            className="mt-2 prose prose-sm max-w-none text-gray-500"
            dangerouslySetInnerHTML={{
              __html: comment,
            }}
          />
        ) : (
          <p className="mt-2 prose prose-sm max-w-none text-gray-400 italic">No comment provided.</p>
        )}
      </div>
    </div>
  );
}

function Ratings({ booking }: RatingsProps) {
  const { lender, borrower, rating } = booking;

  return (
    <div>
      <h2 className="sr-only">Customer Reviews</h2>
      <RatingBlock
        rater={borrower.firstName}
        ratee={lender.firstName}
        avatar={borrower.profilePicture}
        rating={rating.rating}
        comment={rating.comment}
        isBorrower
      />
    </div>
  );
}

export default Ratings;
