import { formatDate } from '../shared/utils';
import { FormatFunction } from '../shared/utils';

export const generateFormattedAvailabilityDetails: FormatFunction = availability =>
  `
LENDER: 
Name: [${availability.lender.firstName}]


AVAILABILITY: 

Availability ID: [${availability.id}] 

Availability start: [${formatDate(availability.start)}]

Availability end: [${formatDate(availability.end)}]

CAR: 
VIN [${availability.vehicle.vin}] 
Registration number: [${availability.vehicle.regNr}]
`;
