import { Helmet } from 'react-helmet';

function Applications() {
  return (
    <div>
      <Helmet>
        <title>Applications - Car Sharing</title>
      </Helmet>
      <div className="flex justify-center py-10">
        <h1 className="text-2xl font-bold">There`s no place like applications</h1>
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="px-2 bg-white text-sm text-gray-500">Coming soon...</span>
        </div>
      </div>
    </div>
  );
}

export default Applications;
