import { GenericFunction } from 'lynkco-up-core/dist/types/types';
import { useEffect, useState } from 'react';

import StatusTabs, { StatusTab } from '../../components/StatusTabs';
import { Booking, Status as BookingStatus } from '../../services/bookings/types';

type BookingQuickFilterProps = {
  bookings: Booking[];
  onChange: GenericFunction;
};

const initialStatusTabs: StatusTab[] = [
  {
    label: 'All',
    current: true,
    id: 0,
    count: 0,
  },
  {
    label: 'Ongoing',
    current: false,
    id: 1,
    count: 0,
  },
  {
    label: 'Error',
    current: false,
    id: 2,
    count: 0,
  },
  {
    label: 'Cancelled',
    current: false,
    id: 3,
    count: 0,
  },
  {
    label: 'Not started',
    current: false,
    id: 4,
    count: 0,
  },
  {
    label: 'Completed',
    current: false,
    id: 5,
    count: 0,
  },
];

function countBookingsByStatus(bookings: Booking[], statusTabs: StatusTab[]): StatusTab[] {
  let ongoingStatusesCount = 0;
  let errorStatusesCount = 0;
  let cancelledStatusesCount = 0;
  let notStartedStatusesCount = 0;
  let completedStatusesCount = 0;
  const ongoingStatuses = [BookingStatus.ACTIVE_BY_START, BookingStatus.ACTIVE_BY_BORROWER];
  const errorStatuses = [BookingStatus.CANCELLED_BY_ERROR, BookingStatus.CANCELLED_BY_PSP];
  const cancelledStatuses = [
    BookingStatus.CANCELLED_BY_BORROWER,
    BookingStatus.CANCELLED_BY_LENDER,
    BookingStatus.CANCELLED_BY_EC,
    BookingStatus.CANCELLED_BY_TIMEOUT,
  ];
  const notStartedStatuses = [BookingStatus.ACCEPTED, BookingStatus.REQUESTED, BookingStatus.REJECTED];
  const completedStatuses = [BookingStatus.FINISHED, BookingStatus.CLOSED];
  const activeTab = statusTabs.find(tab => tab.current);

  bookings.forEach(booking => {
    if (ongoingStatuses.includes(booking.status)) {
      ongoingStatusesCount++;
    }

    if (errorStatuses.includes(booking.status)) {
      errorStatusesCount++;
    }

    if (cancelledStatuses.includes(booking.status)) {
      cancelledStatusesCount++;
    }

    if (notStartedStatuses.includes(booking.status)) {
      notStartedStatusesCount++;
    }

    if (completedStatuses.includes(booking.status)) {
      completedStatusesCount++;
    }
  });

  const tabsWithCount = [
    {
      label: 'All',
      current: false,
      id: 0,
      count: bookings.length,
    },
    {
      label: 'Ongoing',
      current: false,
      id: 1,
      count: ongoingStatusesCount,
    },
    {
      label: 'Error',
      current: false,
      id: 2,
      count: errorStatusesCount,
    },
    {
      label: 'Cancelled',
      current: false,
      id: 3,
      count: cancelledStatusesCount,
    },
    {
      label: 'Not started',
      current: false,
      id: 4,
      count: notStartedStatusesCount,
    },
    {
      label: 'Completed',
      current: false,
      id: 5,
      count: completedStatusesCount,
    },
  ];

  const tabs = tabsWithCount.map(tab => {
    if (tab.label === activeTab?.label) {
      tab.current = true;
    }
    return tab;
  });

  return tabs;
}

function BookingsQuickFilter({ bookings, onChange }: BookingQuickFilterProps): JSX.Element {
  const [tabs, setTabs] = useState(initialStatusTabs);

  const updateTabList = (selectedTabName: string): void => {
    if (!tabs) return;

    const updatedTabs = tabs.map(tab => {
      tab.label === selectedTabName ? (tab.current = true) : (tab.current = false);
      return tab;
    });

    setTabs(updatedTabs);
  };

  const handleOnClick = (label: string) => {
    updateTabList(label);
    onChange(label);
  };

  useEffect(() => {
    if (bookings.length > 0) {
      setTabs(countBookingsByStatus(bookings, tabs));
    } else {
      setTabs(initialStatusTabs);
    }
  }, [bookings]);

  return <StatusTabs tabs={tabs} onChange={handleOnClick} />;
}

export default BookingsQuickFilter;
