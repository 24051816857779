export type ActionStatusDiskProps = {
  actions?: string[];
  extraClasses?: string;
};

export const colorActionMap: Record<string, string> = {
  'Modify Fuel Price': 'bg-yellow-500',
  'Modify Service Fee': 'bg-yellow-500',
  'Remove Availabilities': 'bg-gray-500',
  'Cancelled Booking': 'bg-danger-500',
  'Cancelled Payment Authorization': 'bg-purple-500',
  'Added user': 'bg-blue-500',
  'Deleted user': 'bg-blue-500',
};

const DEFAULT_COLOR = 'bg-gray-500';

export default function ActionStatusDisk({ actions = [], extraClasses = '' }: ActionStatusDiskProps): JSX.Element {
  return (
    <div className="flex">
      {actions.map((action, index) => {
        console.log(action);

        const color = colorActionMap[action] || DEFAULT_COLOR;
        return <div key={index} className={`rounded-full w-3 h-3 ${color} ${extraClasses} mr-2`}></div>;
      })}
    </div>
  );
}
