import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routing';

function Home() {
  const { accounts } = useMsal();
  const userName = accounts[0]?.name || '';

  const navigate = useNavigate();

  useEffect(() => {
    navigate(routes.bookings.path);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Home - Car Sharing Bookings</title>
      </Helmet>
      <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-primary tracking-wide uppercase">WELCOME TO</h2>
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Car Sharing Bookings
          </p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            You are currenly logged in as <strong>{userName}</strong>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
