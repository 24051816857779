import { Tabs } from 'lynkco-up-core';
import { Tab } from 'lynkco-up-core/dist/types/components/Tabs';

import { Availability } from '../../services/availabilities/types';
import RawApiResponse from '../shared/DetailsRawApiResponse';
import Overview from './availabilityDetailsOverview';

type AvailabilityDetailsProps = {
  availability: Availability;
};

function AvailabilityDetails({ availability }: AvailabilityDetailsProps) {
  if (!availability) {
    return null;
  }

  const tabs: Tab[] = [
    {
      name: 'Overview',
      content: <Overview availability={availability} />,
      isActive: true,
    },
    {
      name: 'Raw',
      content: <RawApiResponse data={availability} />,
    },
  ];

  return (
    <div>
      <h2 className="font-medium text-xl mb-2.5">Availability</h2>
      <Tabs tabsName="booking-details" tabs={tabs} />
    </div>
  );
}

export default AvailabilityDetails;
