import { axiosInstance } from '../shared';
import { serviceConfig } from '../../config';
import { User } from './types';

async function getUsers(): Promise<User[]> {
  const url = `${serviceConfig.endpoints.legacy.root}/${serviceConfig.endpoints.legacy.users}`;
  let users;

  try {
    const response = await axiosInstance.get(url);
    users = response.data;
    users.sort((a: User, b: User) => a.username.localeCompare(b.username));
  } catch (err) {
    const error = String(err);
    if (error.includes('403')) throw new Error('Unauthorized');
    else throw new Error('Failed to get users.');
  }
  return users;
}

export default getUsers;
