import axiosInstance from '../shared/axiosInstance';
import { serviceConfig } from '../../config';

async function removeAvailability(availabilityId: string): Promise<null | number> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.availabilities}/${availabilityId}`;
  let result = null;

  try {
    const response = await axiosInstance.delete(url);
    result = response.status;
  } catch (err) {
    throw new Error('Failed to stop vehicle from sharing');
  }

  return result;
}

export default removeAvailability;
