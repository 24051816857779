import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { QueryClientProvider } from 'react-query';

import App from './App';
import { queryClient, authService } from './services/shared';
import { sharedConfig } from './config';

import './tailwind.css';
import '../node_modules/lynkco-up-core/dist/index.css';

const AppProvider = () => {
  return (
    <React.StrictMode>
      <MsalProvider instance={authService}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </MsalProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<AppProvider />, document.getElementById('root'));

console.info(`Current version: ${sharedConfig.version}`);
