import { useContext } from 'react';

import {
  Avatar,
  Button,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  GlobalContext,
  Icon,
  Pagination,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from 'lynkco-up-core';
import { GenericFunction } from 'lynkco-up-core/dist/types/types';

import { LoadingMask } from '../../components';
import { Availability } from '../../services/availabilities/types';
import { getMarketName } from '../shared/utils';
import AvailabilityTimeLabel from './AvailabilityTimeLabel';
import { copyToClipboard } from '../../utils';
import { copyFormattedDetailsToClipboard } from '../shared/utils';
import { useSelectedAvailabilityActions } from '../../modules/Availabilities/selectedAvailabilityReducer';
import { SelectedAvailabilityContext } from '../../modules/Availabilities/selectedAvailabilityContext';
import { SortColumnType } from '../../modules/Bookings';
import SortCell from '../../components/SortCell';
import { useUserContext } from '../../modules/Users/UserContext';

type AvailabilitiesTableProps = {
  data: Availability[];
  isLoading?: boolean;
  paginationStartIndex: number;
  paginationEndIndex: number;
  paginationQuantity: number;
  onPaginationNextChange: GenericFunction;
  onColumnSortChange: GenericFunction;
  columnSort: SortColumnType;
  onPaginationPreviousChange: GenericFunction;
};

export function AvailabilitiesTable({
  data,
  isLoading = false,
  paginationStartIndex,
  paginationEndIndex,
  paginationQuantity,
  onPaginationNextChange,
  onPaginationPreviousChange,
  onColumnSortChange,
  columnSort,
}: AvailabilitiesTableProps) {
  const { alert } = useContext(GlobalContext);
  const hasData = data.length > 0;
  const fillerArray = new Array(5).fill(0).map(() => Math.random());
  const { dispatch } = useContext(SelectedAvailabilityContext);
  const { setSelectedAvailability, openDetailsPanel, setIsStopSharing } = useSelectedAvailabilityActions(dispatch);

  function handleCopyToClipboard(value: string) {
    copyToClipboard(value, () => {
      alert.show('Error copying to clipboard.');
    });
  }

  function handleCopyAllDetailsToClipboard(availability: Availability) {
    copyFormattedDetailsToClipboard(availability, () => {
      alert.show('Error copying to clipboard.');
    });
  }

  function handleShowAvailabilityDetails(availability: Availability) {
    setSelectedAvailability(availability);
    openDetailsPanel();
  }

  function handleStopSharing(availability: Availability) {
    setSelectedAvailability(availability);
    setIsStopSharing();
  }

  function hasAccess(permission: string) {
    const { checkAccess } = useUserContext();
    return checkAccess(permission);
  }

  return (
    <div className="z-10 availabilities-table mb-72">
      <div className="overflow-x-scroll">
        <Table extraClasses="min-w-max">
          <Thead extraClasses="!bg-neutral-50">
            {isLoading ? (
              <Tr>
                <Th>
                  <div className="pl-6 py-1.5">
                    <LoadingMask />
                  </div>
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
              </Tr>
            ) : (
              <Tr>
                <Th>
                  <span className="font-medium pl-6 text-sm text-neutral-400">VIN</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400">LICENSE PLATE</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400">LENDER</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400">MARKET</span>
                </Th>
                <Th extraClasses="!p-0 hover:bg-gray-200">
                  <SortCell sortValue={columnSort.start} sortLabel="start" onClick={onColumnSortChange} />
                </Th>
                <Th extraClasses="!p-0 hover:bg-gray-200">
                  <SortCell sortValue={columnSort.end} sortLabel="end" onClick={onColumnSortChange} />
                </Th>
                <Th extraClasses="sr-only">Dropdown Menu</Th>
              </Tr>
            )}
          </Thead>
          <Tbody>
            {isLoading
              ? fillerArray.map(_ => (
                  <Tr key={_}>
                    <Td>
                      <div className="pl-5 py-3">
                        <LoadingMask />
                      </div>
                    </Td>
                    <Td>
                      <LoadingMask />
                    </Td>
                    <Td>
                      <LoadingMask />
                    </Td>
                    <Td>
                      <LoadingMask />
                    </Td>
                    <Td cellWidth={100} align="right">
                      <LoadingMask />
                    </Td>
                  </Tr>
                ))
              : data.map(entry => (
                  <Tr key={entry.id}>
                    <Td>
                      <span className="font-medium pl-6 text-black">{entry.vehicle?.vin}</span>
                    </Td>
                    <Td>{entry.vehicle?.regNr}</Td>
                    <Td>
                      <div className="flex items-center">
                        <Avatar
                          size="small"
                          alt={entry.lender.firstName}
                          src={entry.lender.profilePicture}
                          extraClasses="border-2 border-gray-100"
                        />
                        <span className="ml-2">{entry.lender?.firstName}</span>
                      </div>
                    </Td>
                    <Td>{getMarketName(entry.vehicle?.market)}</Td>
                    <Td>
                      <>{AvailabilityTimeLabel(entry.start)}</>
                    </Td>
                    <Td>
                      <div className="flex">
                        <p className="text-center">{AvailabilityTimeLabel(entry.end)}</p>
                      </div>
                    </Td>
                    <Td cellWidth={100} align="right">
                      <div className="flex items-center pr-2">
                        <Button
                          onClick={() => handleShowAvailabilityDetails(entry)}
                          variant="transparent"
                          size="small"
                          extraClasses="mr-2">
                          <Icon name="list_alt" size="small" variant="gray" />
                        </Button>
                        <Dropdown icon="more_vert" isBorderless label="">
                          <DropdownItem onClick={() => handleCopyAllDetailsToClipboard(entry)}>
                            <div className="flex justify-between items-center">
                              <span>All details</span>
                              <Icon name="content_copy" />
                            </div>
                          </DropdownItem>
                          <DropdownItem onClick={() => handleCopyToClipboard(entry.id)}>
                            <div className="flex justify-between items-center">
                              <span>Availability ID</span>
                              <Icon name="content_copy" />
                            </div>
                          </DropdownItem>

                          <DropdownItem onClick={() => handleCopyToClipboard(entry.lender.snowflakeId)}>
                            <div className="flex justify-between items-center">
                              <span>Lender user ID</span>
                              <Icon name="content_copy" />
                            </div>
                          </DropdownItem>
                          <DropdownItem onClick={() => handleCopyToClipboard(entry.vehicle.vin)}>
                            <div className="flex justify-between items-center">
                              <span>Vehicle ID (VIN)</span>
                              <Icon name="content_copy" />
                            </div>
                          </DropdownItem>
                          <DropdownItem onClick={() => handleCopyToClipboard(entry.vehicle.regNr)}>
                            <div className="flex justify-between items-center">
                              <span>Vehicle License Plate</span>
                              <Icon name="content_copy" />
                            </div>
                          </DropdownItem>
                          <DropdownDivider />
                          {hasAccess('FUNCTION_STOP_SHARING') && (
                            <DropdownItem onClick={() => handleStopSharing(entry)}>
                              <div className="uppercase text-center font-semibold text-danger-500 p-2 rounded">
                                <span>Stop sharing</span>
                              </div>
                            </DropdownItem>
                          )}
                        </Dropdown>
                      </div>
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
        {!isLoading && !hasData && <p className="text-center text-2xl m-8 text-gray-500">No availabilities found</p>}
      </div>
      {!isLoading && hasData && (
        <div className="px-2 border-t border-gray-200 pagination-container">
          <Pagination
            itemsStartIndex={paginationStartIndex}
            itemsEndIndex={paginationEndIndex}
            itemsQuantity={paginationQuantity}
            onNextChange={onPaginationNextChange}
            onPreviousChange={onPaginationPreviousChange}
          />
        </div>
      )}
    </div>
  );
}

export default AvailabilitiesTable;
