import axiosInstance from '../shared/axiosInstance';
import { serviceConfig } from '../../config';
import { Booking } from './types';

async function getBookingById(bookingId: string): Promise<Booking> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.bookings}/${bookingId}`;
  let result = null;

  try {
    const response = await axiosInstance.get(url);
    result = response.data;
  } catch (err) {
    throw new Error('Error retriving the booking with the specified id');
  }

  return result;
}

export default getBookingById;
