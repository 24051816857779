import React from 'react';
import { Booking } from '../../services/bookings/types';

export type SelectedBookingState = {
  selectedBooking: Booking | null;
  isDetailsPanelOpen: boolean;
  isBookingTermination: boolean;
};

enum SelectedBookingActionType {
  SET_SELECTED_BOOKING = 'setSelectedBooking',
  CLEAR_SELECTED_BOOKING = 'clearSelectedBooking',
  OPEN_DETAILS_PANEL = 'openDetailsPanel',
  CLOSE_DETAILS_PANEL = 'closeDetailsPanel',
  SET_IS_BOOKING_TERMINATION = 'setIsBookingTermination',
  CLEAR_IS_BOOKING_TERMINATION = 'clearIsBookingTermination',
}

export type SelectedBookingAction = {
  type: SelectedBookingActionType;
  payload?: Booking;
};

export const selectedBookingInitialState: SelectedBookingState = {
  selectedBooking: null,
  isDetailsPanelOpen: false,
  isBookingTermination: false,
};

export function selectedBookingReducer(state: SelectedBookingState, action: SelectedBookingAction) {
  switch (action.type) {
    case SelectedBookingActionType.SET_SELECTED_BOOKING:
      if (action.payload) {
        return {
          ...state,
          selectedBooking: action.payload,
        };
      } else {
        console.warn('No payload passed to setBooking action, returning unmodified state.');
        return state;
      }
    case SelectedBookingActionType.CLEAR_SELECTED_BOOKING:
      return {
        ...state,
        selectedBooking: null,
      };
    case SelectedBookingActionType.OPEN_DETAILS_PANEL:
      if (state.selectedBooking) {
        return {
          ...state,
          isDetailsPanelOpen: true,
        };
      } else {
        console.warn('No booking set to open details panel for, returning unmodified state.');
        return state;
      }
    case SelectedBookingActionType.CLOSE_DETAILS_PANEL:
      return {
        ...state,
        isDetailsPanelOpen: false,
      };
    case SelectedBookingActionType.SET_IS_BOOKING_TERMINATION:
      return {
        ...state,
        isBookingTermination: true,
      };
    case SelectedBookingActionType.CLEAR_IS_BOOKING_TERMINATION:
      return {
        ...state,
        isBookingTermination: false,
      };
    default:
      return state;
  }
}

export function useSelectedBookingActions(dispatch: React.Dispatch<SelectedBookingAction>) {
  return {
    setSelectedBooking: (booking: Booking) =>
      dispatch({ type: SelectedBookingActionType.SET_SELECTED_BOOKING, payload: booking }),
    clearSelectedBooking: () => dispatch({ type: SelectedBookingActionType.CLEAR_SELECTED_BOOKING }),
    openDetailsPanel: () => dispatch({ type: SelectedBookingActionType.OPEN_DETAILS_PANEL }),
    closeDetailsPanel: () => dispatch({ type: SelectedBookingActionType.CLOSE_DETAILS_PANEL }),
    setIsBookingTermination: () => dispatch({ type: SelectedBookingActionType.SET_IS_BOOKING_TERMINATION }),
    clearIsBookingTermination: () => dispatch({ type: SelectedBookingActionType.CLEAR_IS_BOOKING_TERMINATION }),
  };
}
