import { CoreProvider } from 'lynkco-up-core';

import Routes from './routing';

import './index.css';
import { UserProvider } from './modules/Users/UserContext';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import LoginPage from './modules/Login';

function App() {
  return (
    <div className="App w-screen h-screen">
      <AuthenticatedTemplate>
        <CoreProvider>
          <UserProvider>
            <Routes />
          </UserProvider>
        </CoreProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
