import { DefinitionDetails } from '../../types';

function ColDefinitionDetails({ label, children }: DefinitionDetails) {
  return (
    <div className="sm:col-span-1">
      <dt className="text-gray-500 tracking-wide text-sm">{label}</dt>
      <dd className="text-slate-900 font-normal">{children}</dd>
    </div>
  );
}

export default ColDefinitionDetails;
