import { serviceConfig } from '../../config';
import { axiosInstance } from '../shared';
import { CommentsQuery, GetProvision } from './types';

async function getProvisions(query: CommentsQuery): Promise<GetProvision[]> {
  const url =
    `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.customerServiceBookings}/` +
    query.bookingId +
    `/provisionstates`;
  let events;

  try {
    const response = await axiosInstance.get(url, {
      params: query,
    });
    events = response.data.stateEvents;
  } catch (err) {
    throw new Error('Failed to get Events.');
  }

  return events;
}

export default getProvisions;
