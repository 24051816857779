import { serviceConfig } from '../../config';
import { axiosInstance } from '../shared';
import { User } from './types';

async function editUser(user: User): Promise<null | number> {
  const url = `${serviceConfig.endpoints.legacy.root}/${serviceConfig.endpoints.legacy.users}/${user.id}/roles`;
  const role = user.roles[0];
  let result = null;

  try {
    const response = await axiosInstance.put(url, { role });
    result = response.status;
  } catch (err) {
    throw new Error(`Failed to edit user: ${user.username}`);
  }

  return result;
}

export async function updateUserLabels() {
  const url = `${serviceConfig.endpoints.legacy.root}/${serviceConfig.endpoints.legacy.users}/update-role-names`;
  return await axiosInstance.get(url);
}

export default editUser;
