import { useContext, useEffect, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import dayjs from 'dayjs';
import { Dialog, GlobalContext, SlideOver } from 'lynkco-up-core';
import { GenericObject } from 'lynkco-up-core/dist/types/types';

import { getAvailabilities, removeAvailability } from '../../services/availabilities';
import { Availability, AvailabilityQuery } from '../../services/availabilities/types';
import { availabilitiesInitialState, availabilitiesReducer, useAvailabilityActions } from './availabilitiesReducer';
import { AvailabilitiesTable } from '../../parts/Availabilities';
import { SelectedAvailabilityContext, SelectedAvailabilityProvider } from './selectedAvailabilityContext';
import { useSelectedAvailabilityActions } from './selectedAvailabilityReducer';
import AvailabilityDetails from '../../parts/Availabilities/availabilityDetails';
import { usePanelResize } from '../../hooks';
import AvailabilitiesFilters from '../../parts/Availabilities/availabilitiesFilters';
import { FiltersState } from '../../parts/Bookings/types';
import { requestDateFormat } from '../../parts/shared/utils';
import { SortColumnType } from '../Bookings';
import { handleAction } from '../HistoryLog/historyLogService';
import { ActionStatus } from '../HistoryLog/types';

const defaultStart = dayjs().startOf('month').format(requestDateFormat);

function Availabilities() {
  const { alert, loading } = useContext(GlobalContext);
  const [, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState<AvailabilityQuery>({
    start: defaultStart,
  });
  const {
    data = [],
    isLoading,
    isError,
  } = useQuery(['availabilities', query], () => getAvailabilities(query), {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const detailsPanelSize = usePanelResize();
  const [availabilitiesState, availabilitiesDispatch] = useReducer(availabilitiesReducer, availabilitiesInitialState);
  const { setAvailabilities, incrementPage, decrementPage } = useAvailabilityActions(availabilitiesDispatch);
  const { state: selectedAvailabilityState, dispatch: selectedAvailabilityDispatch } =
    useContext(SelectedAvailabilityContext);
  const { isDetailsPanelOpen, selectedAvailability, isStopSharing } = selectedAvailabilityState;
  const { clearSelectedAvailability, closeDetailsPanel, clearStopSharing, setSelectedAvailability } =
    useSelectedAvailabilityActions(selectedAvailabilityDispatch);

  const [isStopSharingDialogOpen, setIsStopSharingDialogOpen] = useState(false);

  const [columnSort, setColumnSort] = useState<SortColumnType>({ start: 1, end: 0 });

  function handleSortChange(field: string, value: number) {
    if (value) setColumnSort({ ...columnSort, [field]: -value });
    else setColumnSort({ ...{ start: 0, end: 0 }, [field]: 1 });
  }

  function handleCloseAvailabilityDetails() {
    clearSelectedAvailability();
    closeDetailsPanel();
  }

  function handleUpdateFilters(filters: FiltersState) {
    const startDate = dayjs(filters.startDate);
    const endDate = dayjs(filters.endDate);
    const isValidStartDate = startDate.isValid();
    const isValidEndDate = endDate.isValid();
    const start = isValidStartDate ? startDate.startOf('day').format(requestDateFormat) : undefined;
    const end = isValidEndDate ? endDate.endOf('day').format(requestDateFormat) : undefined;

    const newQuery: AvailabilityQuery = {
      userId: filters.customerId || undefined,
      vin: filters.vin || undefined,
      regNumber: filters.regNr || undefined,
      start,
      end,
      market: filters.country || undefined,
    };

    const hasActiveFilters = Object.values(newQuery).filter(Boolean).length > 0;

    if (!hasActiveFilters) {
      newQuery.start = defaultStart;
      setSearchParams({});
    } else {
      const searchParams = Object.entries(newQuery).reduce((query, [key, value]) => {
        if (value) {
          query[key] = value;
        }

        return query;
      }, {} as GenericObject);

      setSearchParams(searchParams);
    }

    setQuery(newQuery);
  }

  function handleCloseStopSharingDialog() {
    setIsStopSharingDialogOpen(false);
    clearStopSharing();
  }

  async function handleStopSharing() {
    loading.show();

    if (selectedAvailability)
      try {
        await removeAvailability(selectedAvailability.id);
        alert.show(`The availability was sucessfully ended`, 'success');
        const updatedAvailability: Availability = { ...selectedAvailability, deleted: dayjs().format() };
        handleAction(ActionStatus.REMOVING_AVAILABILITIES, [
          { VIN: updatedAvailability.vehicle.vin },
          { customerNumber: updatedAvailability.lender.customerNumber },
        ]);
        setSelectedAvailability(updatedAvailability);
      } catch (err) {
        alert.show(`Could not end the availability`, 'error');
      }
    else {
      alert.show(`Could not end the availability`, 'error');
    }

    loading.hide();
    handleCloseStopSharingDialog();
  }

  function sortData() {
    const name = (columnSort.start == 0 && 'end') || 'start';
    const invalidDates = new Array<Availability>();
    const sortedData = data.sort((a: Availability, b: Availability) => {
      const aDate = dayjs(a[name]);
      const bDate = dayjs(b[name]);
      if (aDate.isBefore(bDate)) {
        return columnSort[name];
      } else if (aDate.isAfter(bDate)) {
        return -columnSort[name];
      } else return 0;
    });
    if (name == 'end')
      return sortedData
        .filter((availability: Availability) => {
          const aDate = dayjs(availability[name]);
          if (!aDate.isValid()) {
            invalidDates.push(availability);
          } else return true;
        })
        .concat(invalidDates);
    return sortedData;
  }

  useEffect(() => {
    if (!isLoading && !isError) {
      const sortedData = sortData();
      setAvailabilities(sortedData);
    }
  }, [data, columnSort]);

  useEffect(() => {
    const shouldOpenStopSharingDialog = selectedAvailability !== null && isStopSharing;
    setIsStopSharingDialogOpen(shouldOpenStopSharingDialog);
  });

  useEffect(() => {
    if (isError) {
      alert.show('Error loading availabilities', 'error');
    }
  }, [isError]);

  return (
    <div>
      <Helmet>
        <title>Shared Cars - Car Sharing</title>
      </Helmet>
      <AvailabilitiesFilters onUpdateFilters={handleUpdateFilters}></AvailabilitiesFilters>
      <AvailabilitiesTable
        data={availabilitiesState.displayedAvailabilities}
        isLoading={isLoading}
        paginationStartIndex={
          /** Add 1 to start index because zero based array indexing is meaningless to humans */
          /** If the current page is 1 set the start index to 1 for the same reason */
          availabilitiesState.currentPage > 1 ? availabilitiesState.paginationStartIndex + 1 : 1
        }
        paginationEndIndex={Math.min(availabilitiesState.paginationQuantity, availabilitiesState.paginationEndIndex)}
        paginationQuantity={availabilitiesState.paginationQuantity}
        onPaginationNextChange={incrementPage}
        onPaginationPreviousChange={decrementPage}
        onColumnSortChange={handleSortChange}
        columnSort={columnSort}
      />
      <Dialog
        isOpen={isStopSharingDialogOpen}
        title="Confirm stop sharing of vehicle"
        icon="warning"
        confirmText="Confirm"
        cancelText="Abort"
        onConfirm={() => handleStopSharing()}
        onClose={handleCloseStopSharingDialog}
        onCancel={handleCloseStopSharingDialog}>
        <div className="mt-4">
          <p>Are you sure you want to stop sharing this vehicle?</p>
        </div>
      </Dialog>
      <SlideOver
        size={detailsPanelSize}
        isOpen={isDetailsPanelOpen}
        onCancel={handleCloseAvailabilityDetails}
        onClose={handleCloseAvailabilityDetails}>
        {selectedAvailability && <AvailabilityDetails availability={selectedAvailability}></AvailabilityDetails>}
      </SlideOver>
    </div>
  );
}

function AvailabilitiesWrapper() {
  return (
    <SelectedAvailabilityProvider>
      <Availabilities />
    </SelectedAvailabilityProvider>
  );
}

export default AvailabilitiesWrapper;
