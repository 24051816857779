import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { GenericObject } from 'lynkco-up-core/dist/types/types';

const filterKeyAliases: GenericObject = {
  userId: 'customerId',
  orderReference: 'orderReference',
  regNumber: 'regNr',
  bookingId: 'bookingId',
  start: 'startDate',
  end: 'endDate',
  market: 'country',
};

const inputDateFormat = 'YYYY-MM-DD';

/**
 * Read URL query parameters and convert them to filter-like key-value pairs
 */
function useUrlQuery() {
  const [query] = useSearchParams();
  const parameters = Array.from(query.entries()).map(([key, value]) => {
    const filterKey = filterKeyAliases[key] || key;

    if (filterKey === 'startDate' || filterKey === 'endDate') {
      return [filterKey, dayjs(value).format(inputDateFormat)];
    }

    return [filterKey, value];
  });

  return parameters;
}

export default useUrlQuery;
