import { createContext, useReducer } from 'react';

import { Utils } from 'lynkco-up-core';

import { ComponentWithChildren } from '../../types';
import {
  SelectedAvailabilityAction,
  selectedAvailabilityInitialState,
  selectedAvailabilityReducer,
  SelectedAvailabilityState,
} from './selectedAvailabilityReducer';

type SelectedAvailabilityContext = {
  state: SelectedAvailabilityState;
  dispatch: React.Dispatch<SelectedAvailabilityAction>;
};

export const SelectedAvailabilityContext = createContext<SelectedAvailabilityContext>({
  state: selectedAvailabilityInitialState,
  dispatch: Utils.noop,
});

SelectedAvailabilityContext.displayName = 'SelectedAvailabilityContext';

export function SelectedAvailabilityProvider({ children }: ComponentWithChildren) {
  const [state, dispatch] = useReducer(selectedAvailabilityReducer, selectedAvailabilityInitialState);
  return (
    <SelectedAvailabilityContext.Provider value={{ state, dispatch }}>{children}</SelectedAvailabilityContext.Provider>
  );
}
