import { useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

import { sharedConfig } from '../../config';

type MapProps = {
  coords: {
    lat: number;
    lng: number;
  };
  locationData?: string[];
  parkingInfo?: string;
};

const apiKey = sharedConfig.gcp;

let loader: Loader | null = null;

if (apiKey) {
  loader = new Loader({
    /** If the key gets rejected for local environment replace it with '' to get the map working in dev mode */
    apiKey,
  });
} else {
  console.warn('Google Maps loader did not initialize, check API key.');
}

const mapId = 'detailsOverviewMap';

const mapConfig: google.maps.MapOptions = {
  zoom: 14,
  disableDefaultUI: true,
  mapTypeControl: false,
  scaleControl: false,
  scrollwheel: false,
  keyboardShortcuts: false,
  gestureHandling: 'none',
};

const markerConfig: google.maps.MarkerOptions = {
  title: 'Pick up & Drop off',
  icon: '/assets/images/map-marker.png',
};

function Map({ coords, locationData = [], parkingInfo }: MapProps) {
  const [hasError, setHasError] = useState(loader === null);
  const mapRef = useRef<HTMLDivElement>(null);
  const center = {
    lat: coords.lat + 0.0015,
    lng: coords.lng + 0.011, // Offset center to the left to allow space for the details card
  };

  useEffect(() => {
    async function loadMap() {
      if (loader && mapRef.current) {
        try {
          await loader.load();
          const map = new google.maps.Map(document.getElementById(mapRef.current.id) as HTMLElement, {
            center,
            ...mapConfig,
          });
          new google.maps.Marker({
            position: coords,
            map,
            ...markerConfig,
          });
        } catch (error) {
          setHasError(true);
          console.error(error);
        }
      }
    }

    loadMap();
  }, []);

  return (
    <div className="w-full aspect-[3/1] border shadow-sm rounded-lg flex items-center justify-center overflow-hidden">
      {hasError ? (
        <div className="text-gray-400 italic">Map could not be loaded</div>
      ) : (
        <div className="relative w-full h-full">
          <div id={mapId} ref={mapRef} className="w-full h-full"></div>
          <div className="absolute top-0 right-0 w-72 h-full p-4">
            <div className="bg-white overflow-hidden rounded-lg h-full w-full p-3">
              <h5 className="text-xs font-medium text-gray-500">Zone</h5>
              <p className="text-xs">{locationData[0]}</p>
              <p className="text-xs">{locationData[1]}</p>
              <p className="text-xs">{locationData[2]}</p>
              <h5 className="text-xs font-medium text-gray-500 mt-3">Parking info</h5>
              <p className="text-xs">{parkingInfo || '-'}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Map;
