import { Button, Dialog, GlobalContext, Icon, Table, Tbody, Td, Th, Thead, Tr } from 'lynkco-up-core';
import { GenericFunction, ReactInputEventHandler } from 'lynkco-up-core/dist/types/types';
import { useContext, useState } from 'react';
import { useUserContext } from '../../../modules/Users/UserContext';
import { deleteUser, editUser } from '../../../services/users';
import { User } from '../../../services/users/types';
import { mapValuesToLabels, userRoles } from '../../Users/utils';

import { handleAction } from '../../../modules/HistoryLog/historyLogService';
import { ActionStatus } from '../../../modules/HistoryLog/types';

type TableProps = {
  data?: User[];
  refetch: GenericFunction;
};

function BOTable({ data, refetch }: TableProps) {
  const { alert, loading } = useContext(GlobalContext);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);

  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  const paginatedRecords = data?.slice(startIndex, endIndex) || [];

  const pageCount = Math.ceil((data?.length || 0) / perPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  function handleOpenDeleteUserDialog(user: User) {
    setUserToDelete(user);
    setIsDeleteUserDialogOpen(true);
  }

  function handleCloseDeleteUserDialog() {
    setIsDeleteUserDialogOpen(false);
    setTimeout(() => {
      setUserToDelete(null);
    }, 200);
  }

  async function handleDeleteUser(user: User) {
    if (!user) {
      alert.show('Failed to delete user.', 'error');
      return;
    }

    try {
      loading.show();
      await deleteUser(user);
      alert.show(`User ${user.username} was deleted successfully.`, 'success');
      handleAction(ActionStatus.DELETED_USER, [{ username: user.username }]);
    } catch (err) {
      alert.show('Failed to delete user.', 'error');
    }

    handleCloseDeleteUserDialog();
    refetch();
    loading.hide();
  }

  function handleOpenEditUserDialog(user: User) {
    setIsEditUserDialogOpen(true);
    setUserToEdit(user);
  }

  function handleCloseEditUserDialog() {
    setIsEditUserDialogOpen(false);
  }

  async function handleEditUser() {
    if (!userToEdit) {
      alert.show(`Failed to update user`, 'error');
      return;
    }
    try {
      loading.show();
      await editUser(userToEdit);
      alert.show(`User ${userToEdit.username} was updated successfully.`, 'success');
    } catch (err) {
      alert.show(`Failed to update the user ${userToEdit.username}.`, 'error');
    }

    handleCloseEditUserDialog();
    refetch();
    loading.hide();
  }

  const handleRoleChange: ReactInputEventHandler = e => {
    if (userToEdit) setUserToEdit({ ...userToEdit, roles: [e.currentTarget.name] });
  };

  function isChecked(name: string) {
    return userToEdit ? name == userToEdit.roles[0] : false;
  }

  function hasAccess(roleValue: string) {
    const scope = 'FUNCTION_ASSIGN_ROLE_' + roleValue.toUpperCase();
    const { checkAccess } = useUserContext();
    return checkAccess(scope);
  }

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < pageCount) {
      handlePageChange(currentPage + 1);
    }
  };

  function handlePerPageSelectChange(value: string): void {
    setPerPage(Number(value));
    setCurrentPage(1);
  }

  return (
    <div className="mx-auto">
      <div className="z-10 bookings-table">
        <Table>
          <Thead>
            <Tr>
              <Th extraClasses="w-3/4">
                <span className="font-medium pl-6">EMAIL </span>
              </Th>
              <Th>
                <span className="font-medium">ROLE</span>
              </Th>
              <Th>
                <span className="sr-only">Edit USER</span>
              </Th>
              <Th>
                <span className="sr-only">EDIT USER</span>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedRecords.map(user => (
              <Tr key={user.id}>
                <Td>
                  <span className="font-medium pl-6 text-black">{user.username}</span>
                </Td>
                <Td>
                  <span className="font-normal">
                    {mapValuesToLabels(user.roles.map(word => word.replace(/^\w/g, e => e.toUpperCase())).join(','))}
                  </span>
                </Td>
                <Td cellWidth={100}>
                  <div className="flex items-center">
                    <Button onClick={() => handleOpenDeleteUserDialog(user)} variant="transparent" size="small">
                      <Icon size="small" name="person_remove" />
                    </Button>
                    <Button
                      extraClasses="ml-2"
                      onClick={() => handleOpenEditUserDialog(user)}
                      variant="transparent"
                      size="small">
                      <Icon name="edit" size="small" />
                    </Button>
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
      <div className="mt-4 flex justify-between p-6 border-t">
        <Button
          onClick={goToPreviousPage}
          extraClasses={`px-3 py-2 mx-1 rounded-md ${
            currentPage === 1 ? 'bg-gray-300 text-gray-600' : 'bg-blue-500 text-white'
          }`}
          isDisabled={currentPage === 1}
          variant="white">
          Previous Page
        </Button>
        <div>
          <label htmlFor="perPageSelect" className="text-gray-500">
            Records per page:{' '}
          </label>
          <select
            id="perPageSelect"
            className="w-16  p-2 border rounded-md bg-white"
            value={perPage}
            onChange={e => handlePerPageSelectChange(e.target.value)}>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={data?.length}>all</option>
          </select>
        </div>
        <div className="text-gray-500">
          Page {currentPage} of {pageCount}
        </div>
        <Button
          onClick={goToNextPage}
          extraClasses={`px-3 py-2 mx-1 rounded-md ${
            currentPage === pageCount ? 'bg-gray-300 text-gray-600' : 'bg-blue-500 text-white'
          }`}
          isDisabled={currentPage === pageCount}
          variant="white">
          Next Page
        </Button>
      </div>
      {data && data.length <= 0 && <p className="text-center text-2xl mt-8 pl-8 mb-8 text-gray-500">No users found</p>}
      <Dialog
        icon={'warning'}
        title="Remove user"
        isOpen={isDeleteUserDialogOpen}
        body={`Are you sure you want to remove ${userToDelete?.username}?`}
        onClose={handleCloseDeleteUserDialog}
        onCancel={handleCloseDeleteUserDialog}
        onConfirm={() => handleDeleteUser(userToDelete as User)}
        confirmText="Yes"
        cancelText="Cancel"></Dialog>
      <Dialog
        icon="edit"
        onCancel={() => handleCloseEditUserDialog()}
        onClose={() => handleCloseEditUserDialog()}
        onConfirm={() => handleEditUser()}
        isOpen={isEditUserDialogOpen}
        title="Edit user"
        confirmText="Save"
        cancelText="Cancel">
        <div className="mt-2 text-gray-600 text-sm">
          <p>{userToEdit?.username}</p>
          <div className="mt-3">
            <div className="py-1.5">
              {userRoles.map(
                role =>
                  hasAccess(role.value) && (
                    <div key={role.value} className="flex justify-start items-center py-1">
                      <input
                        className="focus:ring-primary-500 text-primary-600"
                        name={role.value}
                        id={role.value}
                        value={role.value}
                        type="radio"
                        onChange={handleRoleChange}
                        checked={isChecked(role.value)}
                      />
                      <label className="ml-3" htmlFor={role.value}>
                        {role.label}
                      </label>
                    </div>
                  ),
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default BOTable;
