import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@mui/material';
import ReportTableRow from './reportTableRow';
import { Report } from '../../../services/bookings/types';

interface ReportsTableProps {
  type: string;
  data: Report[];
  isLoading: boolean;
}

const ReportsTable: React.FC<ReportsTableProps> = ({ type, data, isLoading }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (event) {
      // handle the event if needed
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper} elevation={3} sx={{ mb: '2rem' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Reported by</TableCell>
              <TableCell>{type === 'channel' ? 'Reported Channel' : 'Reported user'}</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              data
                .slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage)
                .map((r: Report, i: number) => <ReportTableRow key={'report-' + i} report={r} type={type} />)}
          </TableBody>
        </Table>

        {isLoading && <CircularProgress color="primary" sx={{ m: '2rem auto 0' }} style={{ display: 'block' }} />}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default ReportsTable;
