import { Button, GlobalContext, Input, Table, Tbody, Td, Th, Thead, Tr } from 'lynkco-up-core';
import { useContext, useEffect, useState } from 'react';
import { EditMarketInfo, GetEUMarketInfo, GetMarketInfo, getCountryByCode } from '../../services/bookings/types';
import editMarket from '../../services/markets/editMarkets';
import { PageHeading } from '../../components';
import { handleAction } from '../HistoryLog/historyLogService';
import { ActionStatus } from '../HistoryLog/types';
import { getMarkets, getMarketsFromExcel } from '../../services/markets/getMarkets';

const EnergyFees = () => {
  const { alert } = useContext(GlobalContext);
  async function fetchMarketInfos() {
    try {
      const fetchedMarketInfos: GetMarketInfo[] = await getMarkets();
      fetchedMarketInfos.forEach(item => {
        item.fuelPerLiter = Math.round(item.fuelPerLiter * 100) / 100;
      });
      fetchedMarketInfos.sort((a, b) => getCountryByCode(a.market).localeCompare(getCountryByCode(b.market)));
      setMarkets(fetchedMarketInfos);
    } catch (error) {
      handleError('Failed to fetch market info');
    }
  }

  async function fetchEUMarketInfos() {
    try {
      const fetchedEUMarketInfos: GetEUMarketInfo[] = await getMarketsFromExcel();
      setEUMarkets(fetchedEUMarketInfos);
    } catch (error) {
      handleError('Failed to fetch EU market info');
    }
  }

  function handleError(message: string) {
    alert.show(message, 'error');
  }

  useEffect(() => {
    (async () => {
      await Promise.all([fetchMarketInfos(), fetchEUMarketInfos()]);
    })();
  }, []);

  const [markets, setMarkets] = useState<GetMarketInfo[]>([]);
  const [eumarkets, setEUMarkets] = useState<GetEUMarketInfo[]>([]);
  const [oldValue, setOldValue] = useState<number>();

  const submitFuelChange = () => {
    markets.map(market => {
      if (market.isChanged) {
        const editModel: EditMarketInfo = {
          electricPerKwh: market.electricPerKwh,
          fuel: market.fuelPerLiter,
          buffer: market.buffer,
          currency: market.currency,
          maxCostLimitPerBooking: market.maxCostLimitPerBooking,
          vat: market.vat,
          serviceFees: market.serviceFees,
        };
        editMarket(editModel, market.market)
          .then(() => {
            fetchMarketInfos();
            alert.show('Successfully updated market', 'success');
            handleAction(ActionStatus.MODIFYING_FUEL_FEE, [
              { market: market.market },
              { newValue: editModel.fuel },
              { oldValue },
            ]);
          })
          .catch(() => {
            fetchMarketInfos();
            alert.show('Unable to update market', 'error');
          });
      }
    });
  };

  const renderTableHeader = () => {
    return (
      <Thead>
        <Tr>
          <Th>
            <span className="font-medium text-sm text-neutral-400 max-w-36">Country Name</span>
          </Th>
          <Th>
            <span className="font-medium text-sm text-neutral-400 max-w-36">Currency</span>
          </Th>
          <Th>
            <span className="font-medium text-sm text-neutral-400 max-w-36">Electric Cost/KwH</span>
          </Th>
          <Th>
            <span className="font-medium text-sm text-neutral-400 max-w-36">Fuel Cost/Litre</span>
          </Th>
          <Th>
            <span className="font-medium text-sm text-neutral-400 max-w-36">EU price</span>
          </Th>
        </Tr>
      </Thead>
    );
  };

  const renderTableBody = () => {
    return (
      <Tbody>
        {markets?.map(item => (
          <Tr key={item.market}>
            <Td extraClasses="border-bottom px-4 py-2 font-bold max-w-36 max-h-24">{getCountryByCode(item.market)}</Td>
            <Td extraClasses="border-bottom px-4 py-2 font-medium max-w-36 max-h-24">{item.currency}</Td>
            <Td extraClasses="border-bottom px-4 py-2 max-w-36 max-h-24" align="center">
              <div className="flex items-center">
                <Input
                  type="number"
                  value={item.electricPerKwh || ''}
                  onChange={e => {
                    handleElectricFeeChange(item.market, +e.currentTarget.value);
                  }}
                />
              </div>
            </Td>
            <Td extraClasses="border-bottom px-4 py-2 max-w-36 max-h-24" align="center">
              <div className="flex items-center">
                <Input
                  type="number"
                  value={item.fuelPerLiter || ''}
                  onChange={e => {
                    handleFeeChange(item.market, +e.currentTarget.value);
                  }}
                />
              </div>
            </Td>
            {eumarkets && typeof extractEuValue(item) === 'number' ? (
              <Td extraClasses="justify-items-center max-w-36 max-h-24">
                <span>{extractEuValue(item)} </span>
                <Button variant="white" onClick={() => handleFeeChange(item.market, extractEuValue(item) as number)}>
                  Use value
                </Button>
              </Td>
            ) : (
              <span></span>
            )}
          </Tr>
        ))}
      </Tbody>
    );
  };

  const handleFeeChange = (market: string, newFee: number) => {
    const updatedData = markets.map(item => {
      if (item.market === market) {
        if (!oldValue) {
          setOldValue(item.fuelPerLiter);
        }
        item.isChanged = true;
        return { ...item, fuelPerLiter: newFee };
      }
      return item;
    });
    setMarkets(updatedData);
  };
  const handleElectricFeeChange = (market: string, newFee: number) => {
    const updatedData = markets.map(item => {
      if (item.market === market) {
        if (!oldValue) {
          setOldValue(item.electricPerKwh);
        }
        item.isChanged = true;
        return { ...item, electricPerKwh: newFee };
      }
      return item;
    });
    setMarkets(updatedData);
  };
  function extractEuValue(item: GetMarketInfo): number | string {
    const euValue = eumarkets.find(i => i.country === getCountryByCode(item.market))?.euroSuper95;
    return euValue ? Math.round(euValue * 100) / 100 : 'No Value';
  }
  return (
    <div className="min-h-screen">
      <PageHeading title="Energy Prices" description="A list of country with energy fees lists."></PageHeading>
      <Table extraClasses="min-w-max">
        {renderTableHeader()}
        {renderTableBody()}
      </Table>
      <Button
        onClick={submitFuelChange}
        variant="primary"
        extraClasses="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
        Submit
      </Button>
    </div>
  );
};

export default EnergyFees;
