import { IconName } from 'lynkco-up-core/dist/types/components/Icon';

import { Market, Status as BookingStatus } from '../../services/bookings/types';

export type FiltersState = {
  customerId: string;
  lenderId: string;
  orderReference: string;
  vin: string;
  regNr: string;
  bookingId: string;
  digitalKeyId: string;
  paymentId: string;
  statuses: Set<string>;
  startDate: string;
  endDate: string;
  country: Market;
};

export enum FilterActions {
  SET_TEXT_FILTER = 'SET_TEXT_FILTER',
  SET_STATUS_FILTER = 'SET_STATUS_FILTER',
  CLEAR_STATUS_FILTER = 'CLEAR_STATUS_FILTER',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
}

export enum TerminationText {
  Cancel = 'cancel',
  End = 'end',
}

export type StatusNamesMap = {
  [key in BookingStatus]: { color: string; label?: string };
};

type Currency = {
  name: string;
  symbol?: IconName;
  countryCode: string[];
};

export type CurrencyMap = {
  [key: string]: Currency;
};
